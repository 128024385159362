import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { AiOutlineCaretRight } from "react-icons/ai";
import { AuthContext } from '../../context/AuthContext';
import NoView from "../layouts/NoView";
//import { getAuthenticatedHeaders } from '../common/functions';
import AxioInstance from '../../axios/AxiosConfig';

const Integracion = (props) => {


  const [copy, setCopy] = useState(false);
  const [refreshToken, setRefreshToken] = useState(false);
  const [apiSecret, setApiSecret] = useState("-------");
  const [apiToken, setApiToken] = useState(false);
  const [isUpdatingkeys, setIsUpdatingkeys] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [password, setPassword] = useState('');
  const [username, setUsername] = useState(props.user.username);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const { getAuthenticatedHeaders, permissions } = useContext(AuthContext)
  const canViewcollaboration = permissions.permission_collaborators;

  const getApiAccess = async () => {
    await AxioInstance.post(`/api/access`, {
      headers: await getAuthenticatedHeaders(),
    }).then(response => {
      if (response.data.status === 200) {
        let access = response.data.data;
        setRefreshToken(access.refresh_token);
        setApiToken(access.prefix);
        setIsLoading(false);

      }
    }).catch(error => {

    });
  }

  const checkUserPassword = async () => {

    if (!password) {
      return;
    }
    const data = {
      username: props.user.username,
      password: password
    }
    AxioInstance.post(`/api/login`, { data }, {
      headers: await getAuthenticatedHeaders(),
    })
      .then(response => {
        if (response.data.status === 200) {
          getNewKeyAccess();
          setShowModal(false);
        } else {
          setShowModal(true);
          setError("Contraseña incorrecta");
        }
      })
  }

  const getNewKeyAccess = async () => {
    setIsUpdatingkeys(true);
    await AxioInstance.post(`/api/access/news`, {
      headers: await getAuthenticatedHeaders(),
    }).then(response => {
      if (response.data.status === 200) {
        let access = response.data.data;
        setRefreshToken(access.refresh_token);
        setApiToken(access.prefix);
        setApiSecret(access.api_secret);
        setIsUpdatingkeys(false);
      }

    }).catch(error => {

    });
  }

  const handleCopyKey = (event) => {
    setCopy(event);
    const el = document.createElement('textarea');
    switch (event) {
      case "refresh":
        el.value = refreshToken;
        break;
      case "token":
        el.value = apiToken;
        break;
      case "secret":
        el.value = apiSecret;
        break;
    }
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    setTimeout(() => {
      setCopy(false);
    }, 2000)
  }

  useEffect(() => {
    getApiAccess();
  }, [isLoading])


  return canViewcollaboration ? (
    <>
      <div className="flex md:flex-row flex-wrap p-3">
        <div className="w-full bg-white text-left ">
          <h1 className="p-3 text-lg"> Tus datos de acceso para la API </h1>
          <h3 className="text-xs pl-3">Usa estos datos para utilizar la API de cobru, recuerda leer la <a className="font-medium text-blue-500 underline hover:text-blue-700" target='_blank' href="https://docs.cobru.co/#/" rel="noopener noreferrer">documentación</a></h3>
          <p className="text-left text-lg p-4">Click para copiar</p>
        </div>

        <div className="w-full bg-white text-center flex md:flex-row flex-wrap pl-2">
          <div className="w-full md:w-2/6 bg-white p-4 text-center">
            <p className="text-gray-700 text-lg p-2 font-bold">Refresh Token</p>
            <div className="rounded overflow-hidden shadow-lg px-2 py-4 border-2 border-grey-700">
              <p className="break-words text-left cursor-pointer" onClick={(e) => { handleCopyKey('refresh') }}>{copy === 'refresh' ? "Copiado!" : refreshToken}</p>
            </div>
          </div>


          <div className="w-full md:w-2/6 bg-white p-4 text-center">
            <p className="text-gray-700 text-lg p-2 font-bold">API Token</p>
            <div className="rounded overflow-hidden shadow-lg px-2 py-4 border-2 border-grey-700">
              <p className="break-words text-left cursor-pointer sm:h-12 md:h-16 lg:h-48" onClick={(e) => { handleCopyKey('token') }}>{copy === 'token' ? "Copiado!" : apiToken}</p>
            </div>
          </div>

          <div className="w-full md:w-2/6 bg-white p-4 text-center">
            <p className="text-gray-700 text-lg p-2 font-bold">X-API-KEY</p>
            <div className="rounded overflow-hidden shadow-lg px-2 py-4 border-2 border-grey-700">
              <p className="break-words text-center cursor-pointer sm:h-12 md:h-16 lg:h-48" onClick={(e) => { handleCopyKey('secret') }}> {copy === 'secret' ? "Copiado!" : apiSecret}</p>
            </div>
            <p className="text-red-500 text-xs italic p-2">El secret-token solo es visible una vez al ser generado, si lo has perdido debes volver a generarlo.</p>
          </div>
        </div>

        <div className="w-full bg-white p-1 text-center flex md:flex-row flex-wrap pl-2">
          <button className="bg-green-600 hover:bg-green-600 text-white font-bold py-2 px-4 rounded-full flex" onClick={() => setShowModal(true)}>
            <span className="text-right w-full"> Generar Nuevas API keys </span>
            {!isUpdatingkeys ? <AiOutlineCaretRight className="text-left w-4 mt-1 ml-1" /> :
              <img alt="loading.." className="relative flex justify-center py-3 px-4 mx-auto w-auto" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />}
          </button>
        </div>
      </div>
      {showModal &&
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-2 mx-auto max-w-xs">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <div className="relative p-6 flex-auto">

                  <p className="my-4 text-gray-600 text-lg leading-relaxed">Ingresa tu contraseña</p>
                  <input aria-label="Password" name="password" value={password} onChange={e => setPassword(e.target.value)} type="password" required className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5" placeholder="Contraseña"></input>
                  {error &&
                    <div className="relative flex justify-center py-3 px-4 mx-auto w-auto"><p className="text-red-500 text-xs italic">{error} </p></div>
                  }
                </div>

                <div className="flex items-center justify-end p-6 border-t border-solid border-gray-300 rounded-b">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 rounded-full"
                    type="button"
                    style={{ transition: "all .15s ease" }}
                    onClick={() => setShowModal(false)}
                  >
                    Cerrar
                  </button>
                  <button
                    className="bg-green-600 text-white active:bg-green-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 rounded-full"
                    type="button"
                    style={{ transition: "all .15s ease" }}
                    onClick={checkUserPassword}
                  >
                    Generar
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className=" fixed inset-0 z-40 " style={{
            background: 'rgba(47, 46, 44, 0.5)',
            backdropFilter: 'blur(10px)',
          }}></div>
        </>
      }
    </>
  ) : (
    <NoView />
  )

}

export default Integracion;