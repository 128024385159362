import React, { useState, useEffect, useContext, useRef } from 'react'
import colurs from "../../assets/cobrus/payment_methods/colurs.png"
import logoPath from '../../helpers/logoPath'
const Loading = (props) => {
    return (
        <>
            <div className="flex justify-center items-center w-full h-screen backgroundColurs" style={{ backgroundColor: 'red', }}>
                <img src={colurs} className='w-1/8' />
            </div >
        </>
    )
}

export default Loading
