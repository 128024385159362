import React, { useState, useEffect } from "react";
import { BsCheckCircle } from "react-icons/bs";
import { Button } from "@material-ui/core";
import numeral from "numeral";

import { /* getAuthenticatedHeaders, */ FormatAmount, FormatPhoneNumber, FormatDocument } from '../common/functions';
import moment from 'moment';
export default function ModalDescripcion(props) {

    const [copiar, setCopiar] = useState(false)
    const [browserName, setBrowserName] = useState("");
    const [showModal, setShowModal] = useState(props.open);
    const [sistemaOP, setSistemaOP] = useState("");

    const [color, setColor] = useState("");
    const [colorText, setColorText] = useState("");


    useEffect(() => {
        setColor(
            props.data.state === 0 || props.data.state === 1
                ? "border-yellow-500"
                : props.data.state === 2
                    ? "border-green-500"
                    : props.data.state === 3
                        ? "orange-border"
                        : props.data.state === 4 || props.data.state === 5 || props.data.state === 6
                            ? "border-red-600" :
                            props.data.state === 7
                                ? "border-red-600" : null
        )
        setColorText(props.data.state === 0 || props.data.state === 1
            ? "text-yellow-500"
            : props.data.state === 2
                ? "text-green-500"
                : props.data.state === 3
                    ? "orange-text"
                    : props.data.state === 4 || props.data.state === 5 || props.data.state === 6
                        ? "text-red-600" :
                        props.data.state === 7
                            ? "text-red-600" : null)

    }, [])

    useEffect(() => {
        if (copiar == true) {


            const timer = setTimeout(() => {
                setCopiar(false)
            }, 2500);
        }
    }, [copiar]);
    const title = `REF-${props.data.pk}`;
    /* const userIp = /* props.info.userIp */
    const today = new Date();
    var ampm = today.getHours() >= 12 ? 'pm' : 'am';
    /*     const comision_retiro = props.info.fee;
        const comision_iva_retiro = props.info.fee_iva;
        const gmf = props.info.gmf;
        const payed_amount = props.info.payed_amount;
        const amount = props.info.amount; */

    function formatDate(x) {
        let currentTimestamp = x - 18000000

        /* let date = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' }).format(currentTimestamp) */
        let date = moment(x).format('DD/MM/YYYY HH:mm A')

        return date;
    }
    const bankName = (id) => {
        let banco = ''
        if (id == 0) {
            banco = 'Bancolombia'
        } else if (id == 1) {
            banco = 'Banco Bogota'
        } else if (id == 2) {
            banco = 'Davivienda'
        } else if (id == 3) {
            banco = 'BBVA'
        } else if (id == 4) {
            banco = 'Banco Occidente'
        } else if (id == 5) {
            banco = 'Colpatria'
        } else if (id == 6) {
            banco = 'Banco Popular'
        } else if (id == 7) {
            banco = 'Helm Bank'
        } else if (id == 8) {
            banco = 'Banco Agrario'
        } else if (id == 9) {
            banco = 'Banco BCSC'
        } else if (id == 10) {
            banco = 'Banco CorpBanca'
        } else if (id == 11) {
            banco = 'Banco Citibank'
        } else if (id == 12) {
            banco = 'Banco AV Villas'
        } else if (id == 13) {
            banco = 'Banco ProCredit'
        } else if (id == 14) {
            banco = 'Banco Pichincha'
        } else if (id == 15) {
            banco = 'Banco Bancoomeva'
        } else if (id == 16) {
            banco = 'Banco Santander'
        } else if (id == 17) {
            banco = 'Banco Falabella'
        } else if (id == 18) {
            banco = 'Nequi'
        } else if (id == 19) {
            banco = 'Banco DaviPlata'
        } else if (id == 20) {
            banco = 'Banco Finandina'
        } else if (id == 21) {
            banco = 'Banco Itaú'
        } else if (id == 22) {
            banco = 'Banco Itaú CorpBanca'
        } else if (id == 23) {
            banco = 'Lulo Bank'
        } else if (id == 25) {
            banco = "Ualá"
        } else if (id == 26) {
            banco = "Banco Cooperativo Coopcentral"
        } else if (id == 27) {
            banco = "Rappi pay"
        } else {
            banco = ""
        }
        return banco
    }
    const imgBank = (item) => {
        let image = ''

        if ((item) === ('Bancolombia') || bankName(item) === ('Bancolombia')||(item?.toUpperCase()) === ('cobre'.toUpperCase())||(item?.toUpperCase()) === ('cobre-bancolombia'.toUpperCase())  ) image = require("../../assets/payment_methods/bancolombia.png");
        if ((item) === ('Depósito') || bankName(item) === ('Depósito') || (item) === ('Deposito')) image = require("../../assets/payment_methods/powwi.png");
        if ((item) === ('Banco Bogota') || bankName(item) === ('Banco Bogota')) image = require("../../assets/payment_methods/bancobogota.jpg");
        if ((item) === ('Davivienda') || bankName(item) === ('Davivienda')|| (item) === ('davivienda')) image = require("../../assets/images/bancodavivienda.png");
        if ((item) === ('BBVA') || bankName(item) === ('BBVA')) image = require("../../assets/payment_methods/bbva.png");
        if ((item) === ('Banco Occidente') || bankName(item) === ('Banco Occidente')) image = require("../../assets/payment_methods/bancooccidente.png");
        if ((item) === ('Colpatria') || bankName(item) === ('Colpatria')) image = require("../../assets/payment_methods/colpatria.png");
        if ((item) === ('Banco Popular') || bankName(item) === ('Banco Popular')) image = require("../../assets/payment_methods/bancopopular.png");
        if ((item) === ('Helm Bank') || bankName(item) === ('Helm Bank')) image = require("../../assets/payment_methods/bancohelm.png");
        if ((item) === ('Banco Agrario') || bankName(item) === ('Banco Agrario')) image = require("../../assets/payment_methods/bancoagrario.png");
        if ((item) === ('Banco BCSC') || bankName(item) === ('Banco BCSC')) image = require("../../assets/payment_methods/bancocajasocial.png");
        if ((item) === ('Banco CorpBanca') || bankName(item) === ('Banco CorpBanca')) image = require("../../assets/payment_methods/corpbanca.png");
        if ((item) === ('Banco Citibank') || bankName(item) === ('Banco Citibank')) image = require("../../assets/payment_methods/citibank.jpg");
        if ((item) === ('Avvillas') || bankName(item) === ('Banco AV Villas')) image = require("../../assets/payment_methods/bancoavvillas.png");
        if ((item) === ('Banco ProCredit') || bankName(item) === ('Banco ProCredit')) image = require("../../assets/payment_methods/bancoprocredit.png");
        if ((item) === ('Banco Pichincha') || bankName(item) === ('Banco Pichincha')) image = require("../../assets/payment_methods/bancopichincha.png");
        if ((item) === ('Banco Bancoomeva') || bankName(item) === ('Banco Bancoomeva')) image = require("../../assets/payment_methods/bancoomeva.jpg");
        if ((item) === ('Banco Santander') || bankName(item) === ('Banco Santander')) image = require("../../assets/payment_methods/bancosantander.png");
        if ((item) === ('Banco Falabella') || bankName(item) === ('Banco Falabella')) image = require("../../assets/payment_methods/bancofalabella.png");
        if ((item) === ('Nequi') || bankName(item) === ('Nequi')) image = require("../../assets/payment_methods/nequi.png");
        if ((item) === ('Banco DaviPlata') || bankName(item) === ('Banco DaviPlata')) image = require("../../assets/payment_methods/bancodaviplata.png");
        if ((item) === ('Banco Finandina') || bankName(item) === ('Banco Finandina')) image = require("../../assets/payment_methods/bancofinandina.png");
        if ((item) === ('Banco Itaú') || bankName(item) === ('Banco Itaú')) image = require("../../assets/payment_methods/itau.png");
        if ((item) === ('Banco Itaú CorpBanca') || bankName(item) === ('Banco Itaú CorpBanca')) image = require("../../assets/payment_methods/corpbanca.png");
        if ((item) === ('deposito') || bankName(item) === ('deposito')) image = require("../../assets/images/pagos-gde.png");
        if ((item) === ('Ualá') || bankName(item) === ('Ualá')) image = require("../../assets/images/uala.png");
        if ((item) === ('Rappipay') || bankName(item) === ('Rappi pay')) image = require("../../assets/payment_methods/rappiPay.png");
        if ((item) === ('Banco Cooperativo Coopcentral') || bankName(item) === ('Banco Cooperativo Coopcentral')) image = require("../../assets/images/coopcentral.png");

        return (image)
    }
    const copy = (text) => {

        var id = "el-id-del-textarea";
        var existsTextarea = document.getElementById(id);

        if (!existsTextarea) {

            var textarea = document.createElement("textarea");
            textarea.id = id;
            // Coloca el textarea en el borde superior izquierdo
            textarea.style.position = 'fixed';
            textarea.style.top = 0;
            textarea.style.left = 0;
            textarea.style.textTransform = "lowercase"
            // Asegurate que las dimensiones del textarea son minimas, normalmente 1px 
            // 1em no funciona porque esto generate valores negativos en algunos exploradores
            textarea.style.width = '1px';
            textarea.style.height = '1px';

            // No se necesita el padding
            textarea.style.padding = 0;

            // Limpiar bordes
            textarea.style.border = 'none';
            textarea.style.outline = 'none';
            textarea.style.boxShadow = 'none';

            // Evitar el flasheo de la caja blanca al renderizar
            textarea.style.background = 'transparent';
            document.querySelector("body").appendChild(textarea);

            existsTextarea = document.getElementById(id);
        } else {

        }

        existsTextarea.value = text;
        existsTextarea.select();

        try {
            var status = document.execCommand('copy');
            if (!status) {
                console.error("No se pudo copiar el texto");
            } else {

                setCopiar(true)
            }
        } catch (err) {

        }

    }
    const typeDocument = (id) => {
        let type = ''
        if (id == 0) {
            type = 'CC'
        } else if (id == 1) {
            type = 'CE'
        } else if (id == 2) {
            type = 'TI'
        } else if (id == 3) {
            type = 'NIT'
        } else if (id == 4) {
            type = 'PSP'
        }
        return type
    }
    function CalcularDv(nit) {
        if (nit.length < 10) {



            var arreglo,
                x,
                y,
                z,
                i,
                nit1,
                dv1;
            nit1 = nit.toString().replace(/\D/g, "")
            if (isNaN(nit1)) {


            } else {
                arreglo = new Array(16);
                x = 0;
                y = 0;
                z = nit1.length;
                arreglo[1] = 3;
                arreglo[2] = 7;
                arreglo[3] = 13;
                arreglo[4] = 17;
                arreglo[5] = 19;
                arreglo[6] = 23;
                arreglo[7] = 29;
                arreglo[8] = 37;
                arreglo[9] = 41;
                arreglo[10] = 43;
                arreglo[11] = 47;
                arreglo[12] = 53;
                arreglo[13] = 59;
                arreglo[14] = 67;
                arreglo[15] = 71;
                for (i = 0; i < z; i++) {
                    y = (nit1.substr(i, 1));
                    x += (y * arreglo[z - i]);

                }
                y = x % 11
                if (y > 1) {
                    dv1 = 11 - y;
                } else {
                    dv1 = y;
                }

                return `${nit}-${dv1}`;
            }
        } else {
            return nit.slice(0, 9) + "-" + nit.slice(9, 10)

        }
    }




    return (
        <>
            {showModal ? (
                <>
                    <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">


                        <div className="relative w-pop-up my-6 mx-auto max-w-md">

                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                <button
                                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                    onClick={() => props.modal(false)}
                                >
                                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                        ×
                                    </span>
                                </button>
                                <div className="relative px-6 pb-1 flex-auto">
                                    {copiar && <div className="copiado-modal">Copiado

                                    </div>}

                                    <h3 className="text-3xl font-bold text-trixel-popup centrar-contenido">
                                        {title}
                                    </h3>
                                    {props.data.bank_dispersion !== null && <hr />}

                                    <div className=" justify-between  flex-row">
                                        {props.data.bank_dispersion !== null &&
                                            <div className='flex flex-row  items-center py-3 justify-between' >
                                                <div className='flex flex-row '>
                                                    <img style={{ width: 50, height: 50, marginRight: 20 }} src={imgBank(props.data.bank_dispersion)} />
                                                    <div>
                                                        <p className='text-gray-700 text-xs'>Transferido desde</p>
                                                        <p className="text-gray-700 text-base">{
                                                        props.data.bank_dispersion === "Avvillas" ? "AVVillas" 
                                                        : props.data.bank_dispersion === "deposito" ? "Depósito" 
                                                        :props.data.bank_dispersion === "davivienda" ? "Davivienda"
                                                        :props.data.bank_dispersion?.toUpperCase() === "cobre".toUpperCase()||props.data.bank_dispersion?.toUpperCase() === "cobre-bancolombia".toUpperCase() ? "Bancolombia" 
                                                        : props.data.bank_dispersion === "Deposito" ? "Depósito"
                                                        : props.data.bank_dispersion
                                                        
                                                        }</p>
                                                    </div>
                                                </div>
                                                <div className={` ${color}`} style={{ borderWidth: 1.5 }}>
                                                    <p className={` ${colorText} px-1 py-1 text-sm m-0`}>{props.data.state === 0 || props.data.state === 1
                                                        ? "PENDIENTE"
                                                        : props.data.state === 2
                                                            ? "DEPOSITADO"
                                                            : props.data.state === 3
                                                                ? "CONSIGNADO"
                                                                : props.data.state === 4 || props.data.state === 5 || props.data.state === 6
                                                                    ? "RECHAZADO"
                                                                    : props.data.state === 7
                                                                        ? "REVERSADO"

                                                                        : ""}</p>
                                                </div>
                                            </div>}

                                    </div>
                                    {props.data.description !== "" && <hr />}
                                    <div className="flex items-center mb-4 pt-2  leading-7 margin-10px justify-center">

                                        <button onClick={() => copy(props.data.description)} className=" text-gray-700 flex rounded-full text-sm   button-outline-off copy-cursor italic text-center">
                                            {props.data.description}
                                        </button>
                                    </div>
                                    <hr />
                                    <div className="my-4 py-2 mb-1 text-gray-600 text-lg leading-relaxed  border-solid text-gray-700 rounded">

                                        <div className="flex items-center justify-between px-1 leading-7 modal-nombre margin-10px">
                                            <p className="rounded-full mr-4 text-sm  text-left " >
                                                Nombre
                                            </p>
                                            <button onClick={() => copy(props.data.account_holder_name)} className="rounded-full text-sm text-right button-outline-off copy-cursor lower">
                                                {props.data.account_holder_name}
                                            </button>
                                        </div>

                                        <div className="flex items-center justify-between px-1 leading-7 margin-10px">
                                            <p className="rounded-full mr-4 text-sm text-left">
                                                Documento
                                            </p>
                                            <button onClick={() => copy(props.data.account_holder_document)} className="button-outline-off rounded-full text-sm text-right copy-cursor ">
                                                {typeDocument(props.data.account_holder_document_type)} {props.data.account_holder_document_type == 3 ? `${CalcularDv(props.data.account_holder_document)}` : FormatDocument(props.data.account_holder_document)}
                                            </button>
                                        </div>
                                        <div className="flex items-center justify-between px-1 leading-7 margin-10px">
                                            <p className="rounded-full mr-4 text-sm text-left">
                                                Monto
                                            </p>
                                            <button onClick={() => copy(props.data.payed_amount)} className="rounded-full text-sm text-right button-outline-off copy-cursor">
                                                <span>
                                                    {` ${FormatAmount(props.data.payed_amount, 1)}`}<span style={{ fontSize: 9 }}>{`${FormatAmount(props.data.payed_amount, 2)}`}</span>
                                                </span>

                                            </button>
                                        </div>

                                        <div className="flex items-center justify-between px-1 leading-7 margin-10px">
                                            <p className="rounded-full mr-4 text-sm text-left">
                                                Banco
                                            </p>
                                            <button onClick={() => copy(props.data.bank_name ? props.data.bank_name : props.data.account_bank ? props.data.account_bank : '')} className="rounded-full text-sm text-right button-outline-off copy-cursor">
                                                {(props.data.account_bank !== undefined ? props.data.account_bank : props.data.bank_name)}
                                            </button>
                                        </div>
                                        <div className="flex items-center justify-between px-1 leading-7 margin-10px">
                                            <p className="rounded-full mr-4 text-sm text-left">
                                                Tipo de cuenta
                                            </p>
                                            <button onClick={() => copy(props.data.account_type === 0 ? "Ahorros" : "Corriente")} className="rounded-full text-sm text-right button-outline-off copy-cursor">
                                                {props.data.account_type === 0 ? "Ahorros" : props.data.account_type === 1 ? "Corriente" : ""}
                                            </button>
                                        </div>
                                        <div className="flex items-center justify-between px-1 leading-7 margin-10px">
                                            <p className="rounded-full mr-4 text-sm text-left">
                                                Número de cuenta
                                            </p>
                                            <button onClick={() => copy(props.data.account_number)} className="rounded-full text-sm text-right button-outline-off copy-cursor">
                                                {((props.data.account_bank !== undefined ? props.data.account_bank : props.data.bank_name) === 'Nequi' || (props.data.account_bank !== undefined ? props.data.account_bank : props.data.bank_name) === "Banco DaviPlata") ? FormatPhoneNumber(props.data.account_number) : props.data.account_number}


                                            </button>
                                        </div>
                                        <hr />

                                        {props.data.account_date_registered && <div className="flex items-center justify-between px-1 leading-7 margin-10px margin-top-10px">
                                            <p className="rounded-full mr-4 text-sm text-left">
                                                Inscrito
                                            </p>
                                            <p className="rounded-full text-sm text-right">
                                                {formatDate(props.data.account_date_registered)}
                                            </p>
                                        </div>}
                                        {props.data.date_created && <div className="flex items-center justify-between px-1 leading-7 margin-10px margin-top-10px">
                                            <p className="rounded-full mr-4 text-sm text-left">
                                                Solicitado
                                            </p>
                                            <p className="rounded-full text-sm text-right">
                                                {formatDate(props.data.date_created)}
                                            </p>
                                        </div>}
                                        {props.data.date_consigned && props.data.state < 5 &&
                                            <div className="flex items-center justify-between px-1 leading-7 margin-10px ">
                                                <p className="rounded-full mr-4 text-sm text-left">
                                                    Consignado                                            </p>
                                                <p className="rounded-full text-sm text-right">
                                                    {formatDate(props.data.date_consigned)}
                                                </p>
                                            </div>
                                        }

                                        {props.data.date_deposited && props.data.state !== 5 && props.data.state !== 6 && <div className="flex items-center justify-between px-1 leading-7 margin-10px">
                                            <p className="rounded-full mr-4 text-sm text-left">
                                                Depositado
                                            </p>
                                            <p className="rounded-full text-sm text-right">
                                                {formatDate(props.data.date_deposited)}
                                            </p>
                                        </div>}

                                        {props.data.date_rejected && props.data.state === 5 && <div className=" flex items-center justify-between px-1 leading-7 margin-10px">
                                            <p className="rounded-full mr-4 text-sm text-left">
                                                Rechazado
                                            </p>
                                            <p className="rounded-full text-sm text-right">
                                                {formatDate(props.data.date_rejected)}
                                            </p>
                                        </div>}
                                        {props.data.date_rejected && props.data.state === 6 && <div className=" flex items-center justify-between px-1 leading-7 margin-10px">
                                            <p className="rounded-full mr-4 text-sm text-left">
                                                Rechazado
                                            </p>
                                            <p className="rounded-full text-sm text-right">
                                                {formatDate(props.data.date_rejected)}
                                            </p>
                                        </div>}

                                    </div>
                                </div>
                                {!!props.data.note && <hr />}
                                {!!props.data.note && <div className=" justify-center items-center">
                                    <p className=" text-center py-4 text-gray-700  text-sm">{props.data.note}</p>
                                </div>}
                                <div className="text-2xl centrar-contenido">

                                </div>
                                <div className="items-center justify-end p-2 border-gray-300 rounded-b">
                                    <p className="text-gray-700 text-center text-xs font-hairline">

                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className=" fixed inset-0 z-40 " style={{
                        background: 'rgba(47, 46, 44, 0.5)',
                        backdropFilter: 'blur(10px)',
                    }}></div>
                </>
            ) : null}
        </>
    );
}
