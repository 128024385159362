const domain = "portal.colurs.io";
const mutuDomain = "panel.mutuahorro.com";
const cobruDomain = "portal.colurs.io";
const ezyDomain = "panel2.ezytransfer.co";


let variablesDomain = {
  classNameColor: "green-600",
  colorPrimary: "#1CCE28",
};

switch (domain) {
  case cobruDomain:
    variablesDomain = {
      classNameColor: "green-600",
      colorPrimary: "#1CCE28",
    };
    break;
  case mutuDomain:
    variablesDomain = {
      classNameColor: "mainColorMutu",
      colorPrimary: "#3D4BCD",
    };
    break;
  case ezyDomain:
    variablesDomain = {
      classNameColor: "mainColorEzy",
      colorPrimary: "#2e86c1",
    };
    break;
  default:
    variablesDomain = variablesDomain;

    break;
}

export default variablesDomain;
