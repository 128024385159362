import React, { useEffect, useState, useContext, Fragment, useRef } from 'react';
import axios from 'axios';
import { /* getAuthenticatedHeaders,  */FormatAmount, formatCurrency } from '../common/functions';
import DataTable from 'react-data-table-component';
import CircularProgress from '@material-ui/core/CircularProgress';
import NativeSelect from '@material-ui/core/NativeSelect';
import Button from '../../components/Button';
import _, { result } from 'lodash';
import moment from 'moment';
import AxioInstance from '../../axios/AxiosConfig';

import Banner from '../../components/Banner';
import { FunctionContext } from '../../context/FuntionsContext';
import { AuthContext } from '../../context/AuthContext';
import { GrDocumentUpload } from "react-icons/gr"
const Operacion = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [ShowBanner, setShowBanner] = useState(false);
    const [errorBanner, setErrorBanner] = useState(false);
    const [fileTxt, setFileTxt] = useState("");
    const [info, setInfo] = useState([]);

    const { reloadOperation } = useContext(FunctionContext)
    const { getAuthenticatedHeaders, setMontoBolsaEfecty, setMontoBolsaBaloto, getAccessToken } = useContext(AuthContext)
    const [balanceMulti, setBalanceMulti] = useState(0);
    const [errorBannerConfirm, setErrorBannerConfirm] = useState(false);
    const [loadingBolsa, setLoadingBolsa] = useState(false)
    const [loadingBankAccountInfo, setLoadingBankAccountInfo] = useState(false)
    const [bolsaBemovil, setBolsaBemovil] = useState({})
    const [ShowBannerConfirm, setShowBannerConfirm] = useState(false)
    const [bankAccountInfo, setBankAccountInfo] = useState({})
    const [showConfirm, setShowConfirm] = useState({
        bank: false,
        third: false
    })
    const [reloadPendings, setReloadPendings] = useState(false);
    const [efectyBolsa, setEfectyBolsa] = useState(0);
    const [balotoBolsa, setBalotoBolsa] = useState(0);
    const [playTechBolsa, setPlayTechBolsa] = useState(0);
    const fileInput = useRef(null)



    const paginationOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos'
    };

    const getData = async () => {

        setIsLoading(true);
        try {
            const response = await AxioInstance.post(`/api/operations`, {
                headers: await getAuthenticatedHeaders(),
            });

            if (response.data.status === 200) {

                setInfo(response.data.data);

                setIsLoading(false);
            }
            setIsLoading(false);

        } catch (error) {

            setIsLoading(false);
        }
        // setIsLoading(false);
    }

    const getBankAccountInscriptionInfo = async () => {
        setLoadingBankAccountInfo(true)
        try {
            const response = await AxioInstance.post('/api/bank_account_inscription_info', {
                headers: await getAuthenticatedHeaders()
            })
            if (response.data.data) {
                setLoadingBankAccountInfo(false)

                setBankAccountInfo(response.data.data)
            } else {
                setBankAccountInfo({
                    pendingBankAccounts: 0, thirdPartyPendingBankAccounts: 0
                })
                setLoadingBankAccountInfo(false)
            }

        } catch (error) {

            setLoadingBankAccountInfo(false)

        }
    }

    const getBusiness = async () => {
        setLoadingBolsa(true)
        Promise.all([getBusinessReload(), getBusinessProducts(), getEfecty(), getBaloto(), getPlayTech()])
            .then(function (results) {
                const getBusinessReload = results[0];
                const getBusinessProducts = results[1];
                const efecty = results[2]
                const baloto = results[3]
                const play = results[4]
                setBolsaBemovil({
                    reload: getBusinessReload,
                    products: getBusinessProducts
                })


                setEfectyBolsa(efecty)
                setBalotoBolsa(baloto)
                setPlayTechBolsa(play)
                setLoadingBolsa(false)
                setMontoBolsaEfecty({
                    bolsa: efecty,
                    alerta: efecty < 2000000 ? true : false
                })
                setMontoBolsaBaloto({
                    bolsa: baloto,
                    alerta: baloto < 2000000 ? true : false
                })
            }).catch(reason => {
                setLoadingBolsa(false)

            })
    }

    const getBusinessReload = async () => {
        try {
            const response = await AxioInstance.post(`/api/get_business_constant/`, {
                id: 130,
                headers: await getAuthenticatedHeaders(),
            });


            if (response.data.status === 200) {

                return response.data.data.int_value

            } else {
                return ''

            }
        } catch (error) {

            return ''

            //setIsLoading(false);
        }
    }
    const InscripcionesConfirm = async () => {
        try {
            const response = await AxioInstance.post(`/api/third_party_bank_account_inscription_confirm/`, {
                headers: await getAuthenticatedHeaders(),
            });


            if (response.data.status === 200) {
                setErrorBannerConfirm(false)
                setShowBannerConfirm(true)

            } else {
                setErrorBannerConfirm(true)
                setShowBannerConfirm(true)

            }
        } catch (error) {
            setErrorBannerConfirm(true)
            setShowBannerConfirm(true)

            //setIsLoading(false);
        }
    }
    const getBusinessProducts = async () => {
        try {
            const response = await AxioInstance.post(`/api/get_business_constant/`, {
                id: 131,
                headers: await getAuthenticatedHeaders(),
            });


            if (response.data.status === 200) {

                return response.data.data.int_value

            } else {
                return ''
            }

        } catch (error) {

            return ''

            //setIsLoading(false);
        }
    }
    const getBusinessMultiproduct = async () => {
        try {
            const response = await AxioInstance.post(`/api/get_business_multi/`, {

                headers: await getAuthenticatedHeaders(),
            });


            if (response.data.status === 200) {
                setBalanceMulti(response.data.data.balance)

            } else {
                return ''
            }

        } catch (error) {

            return ''

            //setIsLoading(false);
        }
    }
    const getEfecty = async () => {
        try {
            const response = await AxioInstance.post(`/api/get_business_constant/`, {
                id: 929,
                headers: await getAuthenticatedHeaders(),
            });


            if (response.data.status === 200) {
                return response.data.data.int_value
            } else {
                return ''
            }

        } catch (error) {

            return ''

            //setIsLoading(false);
        }
    }
    const getBaloto = async () => {
        try {
            const response = await AxioInstance.post(`/api/get_business_constant/`, {
                id: 128,
                headers: await getAuthenticatedHeaders(),
            });


            if (response.data.status === 200) {
                return response.data.data.int_value
            } else {
                return ''
            }

        } catch (error) {

            return ''

            //setIsLoading(false);
        }
    }
    const getPlayTech = async () => {
        try {
            const response = await AxioInstance.post(`/api/get_business_constant/`, {
                id: 125,
                headers: await getAuthenticatedHeaders(),
            });


            if (response.data.status === 200) {
                return response.data.data.int_value
            } else {
                return ''
            }

        } catch (error) {

            return ''

            //setIsLoading(false);
        }
    }



    const downloadTxt = async (data, isTirdPart) => {

        if (isTirdPart) {
            const link = document.createElement('a');
            if (data == null) return;
            const filename = `inscripcion-${moment().format('YYYY-MM-DD-HH:mm A')}.txt`;
            link.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(data));
            link.setAttribute('download', filename);
            link.click();
            //window.location.reload();
        } else {
            const link = document.createElement('a');
            if (data == null) return;
            const filename = `inscripcion-${moment().format('YYYY-MM-DD-HH:mm:ss A')}.txt`;
            link.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(data));
            link.setAttribute('download', filename);
            link.click();
            //window.location.reload();
        }
    }

    const downloadPending = async () => {
        setLoadingBankAccountInfo(true)
        setShowConfirm({
            bank: false,
            third: false
        })
        try {
            const response = await AxioInstance.post('/api/bank_account_inscription_file', {
                headers: await getAuthenticatedHeaders()
            })
            setShowConfirm({
                bank: true,
                third: false
            })
            downloadTxt(response.data.data)
            setLoadingBankAccountInfo(false)
            setReloadPendings(!reloadPendings)
        } catch (error) {
            setLoadingBankAccountInfo(false)
            setShowConfirm({
                bank: false,
                third: false
            })

        }
    }
    const inscriptionConfirm = async () => {
        setLoadingBankAccountInfo(true)
        setShowConfirm({
            bank: false,
            third: false
        })
        try {
            const response = await AxioInstance.post('/api/bank_account_inscription_confirm', {
                headers: await getAuthenticatedHeaders()
            })

            setReloadPendings(!reloadPendings)
        } catch (error) {

            setLoadingBankAccountInfo(false)
        }
    }
    const inscriptionThirdPartConfirm = async () => {
        setLoadingBankAccountInfo(true)
        setShowConfirm({
            bank: false,
            third: false
        })
        try {
            const response = await AxioInstance.post('/api/third_party_bank_account_inscription_confirm', {
                headers: await getAuthenticatedHeaders()
            })

            setReloadPendings(!reloadPendings)
        } catch (error) {

            setLoadingBankAccountInfo(false)
        }
    }
    const downloadPendingTirdPart = async () => {
        setLoadingBankAccountInfo(true)
        setShowConfirm({
            bank: false,
            third: false
        })
        try {
            const response = await AxioInstance.post('/api/third_party_bank_account_inscription_file', {
                headers: await getAuthenticatedHeaders()
            })

            setShowConfirm({
                bank: false,
                third: true
            });
            setReloadPendings(!reloadPendings)
            downloadTxt(response.data.data, true)
            //setLoadingBankAccountInfo(false)
        } catch (error) {
            setLoadingBankAccountInfo(false)
            setShowConfirm({
                bank: false,
                third: false
            })
        }
    }
    useEffect(() => {
        const abortController = new AbortController();
        return () => abortController.abort();
    }, [])



    useEffect(() => {
        getData();
        getBusinessMultiproduct()
    }, [reloadOperation])

    useEffect(() => {
        getBankAccountInscriptionInfo()
    }, [])

    useEffect(() => {
        getBusiness()
    }, [reloadOperation])

    useEffect(() => {
        getBankAccountInscriptionInfo()
    }, [reloadPendings, reloadOperation])


    const AgregarArchivo = async (e) => {
        e.preventDefault();
        /*  let formData = new FormData();
         let archivo = (e.target.files[0]);
         formData.append('files', e.target.files[0])

 
         const blob = new Blob([archivo], { type: "text/plain" });
         var url = URL.createObjectURL(blob)

         var reader = new FileReader();
         reader.addEventListener("loadend", function () {
             // reader.result contains the contents of blob as a typed array
         });
         reader.readAsArrayBuffer(blob);

 
         const response = await axios.post('https://dev.cobru.co/api/update/file', formData, {
             headers: await getAuthenticatedHeaders(true),
 
 
         }) */
        setFileTxt(fileInput.current.files[0].name)
        let archivos = e.target.files;
        const formData = new FormData();
        formData.append('file', archivos[0])
        const DEV = localStorage.getItem('DEV');

        const headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + await getAccessToken(),
            'x-api-key': DEV === "https://dev.cobru.co/" ? process.env.REACT_APP_API_KEY_DEV : process.env.REACT_APP_API_KEY

        };

        axios.post((DEV) + 'panel/load_payments_thirdpartywithdraws/', formData, { headers }, {
        }).then(res => { // then print response status
                        if (res.data.data === "OK") {
                setShowBanner(true)
                setErrorBanner(false)
            } else {
                setShowBanner(true)
                setErrorBanner(true)
            }



        }).catch((e) => {
        
            setShowBanner(true)
            setErrorBanner(true)
        })
    }

    return (
        <>
            {ShowBanner && <Banner
                title={'Proceso realizado'}
                description={errorBanner ? `no se pudo subir el archivo` : "Subida de archivo exitosa"}
                setShowBanner={setShowBanner}
                error={errorBanner}
            />}
            {ShowBannerConfirm && <Banner
                title={'Proceso realizado'}
                description={errorBannerConfirm ? `hubo un problema` : "Se marcaron las cuentas como inscritas"}
                setShowBanner={setShowBannerConfirm}
                error={errorBannerConfirm}
            />}
            <div className="grid gap-x-2 gap-y-2 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 ml-3">

                <div className="w-full p-4 text-center pl-0">

                    <div className="bg-white  rounded overflow-hidden shadow-lg">
                        <div className="px-2 py-4">
                            <div className={`font-bold text-xl mb-2 text-black ${isLoading ? "mt-6" : "mt-8"}`}>

                                {isLoading ? <CircularProgress size={31} />
                                    : info[0] ? formatCurrency("es-CO", "COP", 2, info[0].deposito).split(",")[0] : 0
                                }

                            </div>
                            <p className="text-gray-700 text-xs">
                                Disponible Depósito
                            </p>

                        </div>
                    </div>
                </div>
                <div className="w-full p-4 text-center pl-0">
                    <div className="bg-white   rounded overflow-hidden shadow-lg">
                        <div className="px-2 py-4">
                            <div className={`font-bold text-xl mb-2 text-black ${loadingBolsa ? "mt-6" : "mt-8"}`}>

                                {loadingBolsa ? <CircularProgress size={31} />
                                    : FormatAmount(playTechBolsa)
                                }
                                {/* { isLoading ?  <img alt="loading.." className="relative flex justify-center py-3 px-4 mx-auto w-auto" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                  :   info[0] ? 
                                      info[0].constants.map((inf, i) => {

                                          return (
                                            inf.id === 125 &&
                                            (
                                                <Fragment key={i}>
                                                    {FormatAmount(inf.value)}
                                                </Fragment>
                                            )
                                          )
                                      })
                                   : 0
                                  } */}

                            </div>
                            <p className="text-gray-700 text-xs">
                                Disponible Playtech
                            </p>

                        </div>
                    </div>
                </div>
                <div className="w-full  p-4 text-center pl-0">
                    <div className="bg-white  rounded overflow-hidden shadow-lg">
                        <div className="px-2 py-4">
                            <div className={`font-bold text-xl mb-2 text-black ${loadingBolsa ? "mt-6" : "mt-8"}`}>
                                {loadingBolsa ? <CircularProgress size={31} />
                                    : FormatAmount(balotoBolsa)
                                }
                                {/* { isLoading ?  <img alt="loading.." className="relative flex justify-center py-3 px-4 mx-auto w-auto" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                  :   info[0] ? 
                                      info[0].constants.map((inf, i) => {

                                          return (
                                            inf.id === 128 &&
                                            (
                                                <Fragment key={i}>
                                                    {FormatAmount(inf.value)}
                                                </Fragment>
                                            )
                                          )
                                      })
                                   : 0
                                  } */}
                            </div>
                            <p className="text-gray-700 text-xs">
                                Disponible Baloto
                            </p>
                        </div>
                    </div>
                </div>
                <div className="w-full  p-4 text-center pl-0">
                    <div className="bg-white  rounded overflow-hidden shadow-lg">
                        <div className="px-2 py-4">
                            <div className={`font-bold text-xl mb-2 text-black ${loadingBolsa ? "mt-6" : "mt-8"}`}>
                                {loadingBolsa ? <CircularProgress size={31} />
                                    : FormatAmount(efectyBolsa)

                                }

                                {/* { isLoading ?  <img alt="loading.." className="relative flex justify-center py-3 px-4 mx-auto w-auto" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                    :   info[0]? FormatAmount(info[0].efecty) : 0
                                    } */}
                            </div>
                            <p className="text-gray-700 text-xs">
                                Disponible Efecty
                            </p>
                        </div>
                    </div>
                </div>
                <div className="w-full  p-4 text-center pl-0">
                    <div className="bg-white  rounded overflow-hidden shadow-lg">
                        <div className="px-2 py-4">
                            <div className={`font-bold text-xl mb-2 text-black ${loadingBolsa ? "mt-6" : "mt-8"}`}>
                                {loadingBolsa ? <CircularProgress size={31} />
                                    : bolsaBemovil ?
                                        FormatAmount(balanceMulti) : '0'}
                            </div>
                            <p className="text-gray-700 text-xs">
                                Disponible Megared
                            </p>
                        </div>
                    </div>
                </div>
                {/*  <div className="w-full  p-4 text-center pl-0" >
                    <div className="bg-white  rounded overflow-hidden shadow-lg" style={{ height: 120, }}>
                        <div className="px-2 py-4 flex justify-center flex-col items-center ">

                            <label className=" mb-3 mt-8   text-gray-600  cursor-pointer" style={{ fontSize: '12px' }}>
                                <input type="file" className="hidden" multiple onChange={(e) => AgregarArchivo(e)}
                                    accept="text/plain"
                                    ref={fileInput}
                                    autoFocus={false}
                                />
                                <GrDocumentUpload size="2em" />

                            </label>
                            {fileTxt &&
                                <div className="" >
                                    <p style={{ fontSize: '12px' }}>se subio el archivo:</p>
                                    <p style={{ fontSize: '12px' }}>{fileTxt}</p>
                                </div>
                            }
                            {fileTxt == "" && <p className="text-gray-700 text-xs">
                                Archivo respuesta banco
                            </p>}


                        </div>
                    </div>
                </div> */}
                {/*  <div className="w-full  p-4 text-center pl-0">
                    <div className="bg-white  rounded overflow-hidden shadow-lg flex justify-center" style={{ height: 120, }}>
                        <div className="px-2 py-4 flex flex-col  items-center justify-around">


                            <button className="py-1 rounded-full  border-2 buttonAzul" style={{ width: "340px", borderColor: "#2E86C1", color: "#2E86C1" }}
                                onClick={() => {
                                    if (bankAccountInfo.thirdPartyPendingBankAccounts !== 0) {
                                        if (showConfirm.third === true) {
                                            inscriptionThirdPartConfirm()
                                        } else {
                                            downloadPendingTirdPart()
                                        }
                                    } else {

                                    }
                                }}>{loadingBankAccountInfo ? <p>Espere...</p> : <p>{`Descargar ${!!bankAccountInfo.thirdPartyPendingBankAccounts ? bankAccountInfo.thirdPartyPendingBankAccounts : 0} cuentas para inscribir`}</p>}</button>
                            <button onClick={() => {
                                if (bankAccountInfo.thirdPartyOnProcessBankAccounts !== 0) {
                                    InscripcionesConfirm()
                                }
                            }
                            } className="py-1 rounded-full  border-trixel-green text-trixel-green	border-2 hover:bg-trixel-green hover:text-white" style={{ width: "340px" }}>{loadingBankAccountInfo ? <p>Espere...</p> : `Marcar ${!!bankAccountInfo.thirdPartyOnProcessBankAccounts ? bankAccountInfo.thirdPartyOnProcessBankAccounts : 0} cuentas como inscritas`}</button>
                        </div>
                    </div>
                </div> */}
                <div className="w-full  p-4 text-center pl-0">
                    <div className="bg-white  rounded overflow-hidden shadow-lg">
                        <div className="px-2 py-4">
                            <div className={`font-bold text-xl mb-2 text-black ${isLoading ? "mt-6" : "mt-8"}`}>
                                {isLoading ? <CircularProgress size={31} />
                                    : info[0] ? info[0].pending_withdraw : 0
                                }
                            </div>

                            <p className="text-gray-700 text-xs">
                                Retiros pendientes
                            </p>
                        </div>
                    </div>
                </div>
                <div className="w-full  p-4 text-center pl-0">
                    <div className="bg-white  rounded overflow-hidden shadow-lg">
                        <div className="px-2 py-4">
                            <div className={`font-bold text-xl mb-2 text-black ${loadingBolsa ? "mt-6" : "mt-8"}`}>
                                {loadingBolsa ? <CircularProgress size={31} />
                                    : bolsaBemovil ?
                                        FormatAmount(bolsaBemovil.products) : '0'}
                            </div>
                            <p className="text-gray-700 text-xs">
                                Bemovil Productos
                            </p>
                        </div>
                    </div>
                </div>
                <div className="w-full  p-4 text-center pl-0">
                    <div className="bg-white  rounded overflow-hidden shadow-lg">
                        <div className="px-2 py-4">
                            <div className={`font-bold text-xl mb-2 text-black ${loadingBolsa ? "mt-6" : "mt-8"}`}>
                                {loadingBolsa ? <CircularProgress size={31} />
                                    : bolsaBemovil ?
                                        FormatAmount(bolsaBemovil.reload) : '0'}
                            </div>
                            <p className="text-gray-700 text-xs">
                                Bemovil Recargas
                            </p>
                        </div>
                    </div>
                </div>
{/*                 <div className="w-full  p-4 text-center pl-0">
                    <div className="bg-white  rounded overflow-hidden shadow-lg">
                        <div className="px-2 py-4">
                            <div className={`font-bold text-xl mb-2 text-black ${loadingBolsa ? "mt-6" : "mt-8"}`}>
                                {loadingBolsa ? <CircularProgress size={31} />
                                    : bolsaBemovil ?
                                        FormatAmount(777277) : '0'}
                            </div>
                            <p className="text-gray-700 text-xs">
                                saldo 1
                            </p>
                        </div>
                    </div>
                </div>
                <div className="w-full  p-4 text-center pl-0">
                    <div className="bg-white  rounded overflow-hidden shadow-lg">
                        <div className="px-2 py-4">
                            <div className={`font-bold text-xl mb-2 text-black ${loadingBolsa ? "mt-6" : "mt-8"}`}>
                                {loadingBolsa ? <CircularProgress size={31} />
                                    : bolsaBemovil ?
                                        FormatAmount(777277) : '0'}
                            </div>
                            <p className="text-gray-700 text-xs">
                                saldo 2
                            </p>
                        </div>
                    </div>
                </div>
                <div className="w-full  p-4 text-center pl-0">
                    <div className="bg-white  rounded overflow-hidden shadow-lg">
                        <div className="px-2 py-4">
                            <div className={`font-bold text-xl mb-2 text-black ${loadingBolsa ? "mt-6" : "mt-8"}`}>
                                {loadingBolsa ? <CircularProgress size={31} />
                                    : bolsaBemovil ?
                                        FormatAmount(777277) : '0'}
                            </div>
                            <p className="text-gray-700 text-xs">
                                saldo 3
                            </p>
                        </div>
                    </div>
                </div>
                <div className="w-full  p-4 text-center pl-0">
                    <div className="bg-white  rounded overflow-hidden shadow-lg">
                        <div className="px-2 py-4">
                            <div className={`font-bold text-xl mb-2 text-black ${loadingBolsa ? "mt-6" : "mt-8"}`}>
                                {loadingBolsa ? <CircularProgress size={31} />
                                    : bolsaBemovil ?
                                        FormatAmount(777277) : '0'}
                            </div>
                            <p className="text-gray-700 text-xs">
                                saldo 4
                            </p>
                        </div>
                    </div>
                </div> */}

            </div>
            {/* <div className="flex md:flex-row flex-wrap pl-4 pr-4">
                <div className="w-full md:w-1/4 p-4 text-center pl-0">
                    <div className="max-w-sm bg-white rounded overflow-hidden shadow-lg">
                        <div className="px-2 py-4">
                            <div className="font-bold text-xl mb-2 mt-8 text-black">
                                { isLoading ?  <img alt="loading.." className="relative flex justify-center py-3 px-4 mx-auto w-auto" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                :   info[0] ? formatCurrency("es-CO", "COP", 2, info[0].deposito).split(",")[0] : 0
                                }
                                
                                
                            </div>
                            <p className="text-gray-700 text-xs">
                                Disponible Depósito
                            </p>
                        </div>
                    </div>
                </div>
                <div className="w-full md:w-1/4 p-4 text-center pl-0">
                        <div className="max-w-sm bg-white rounded overflow-hidden shadow-lg">
                            <div className="px-2 py-4">
                                <div className="font-bold text-xl mb-2 text-black mt-8">
                                  
                                { isLoading ?  <img alt="loading.." className="relative flex justify-center py-3 px-4 mx-auto w-auto" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                :   info[0] ? FormatAmount(info[0].constants[1].value) : 0
                                }

                                </div>
                                <p className="text-gray-700 text-xs">
                                     Disponible Playtech
                                </p>
                            </div>
                        </div>
                    </div>
                <div className="w-full md:w-1/4 p-4 text-center pl-0">
                        <div className="max-w-sm bg-white rounded overflow-hidden shadow-lg">
                            <div className="px-2 py-4">
                                <div className="font-bold text-xl mb-2 text-black mt-8">
                                    { isLoading ?  <img alt="loading.." className="relative flex justify-center py-3 px-4 mx-auto w-auto" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                    :   info[0] ? FormatAmount(info[0].constants[0].value) : 0
                                    }
                                </div>
                                <p className="text-gray-700 text-xs">
                                    Disponible Baloto
                                </p>
                            </div>
                        </div>
                    </div>
                <div className="w-full md:w-1/4 p-4 text-center pl-0">
                        <div className="max-w-sm bg-white rounded overflow-hidden shadow-lg">
                            <div className="px-2 py-4">
                                <div className="font-bold text-xl mb-2 text-black mt-8">
                                    { isLoading ?  <img alt="loading.." className="relative flex justify-center py-3 px-4 mx-auto w-auto" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                    :   info[0]? FormatAmount(info[0].efecty) : 0
                                    }
                                </div>
                                <p className="text-gray-700 text-xs">
                                    Disponible Efecty
                                </p>
                            </div>
                        </div>
                    </div>             
            </div>

             <div className="flex md:flex-row flex-wrap pl-4 pr-4">
                <div className="w-full md:w-1/4 p-4 text-center pl-0">
                        <div className="max-w-sm bg-white rounded overflow-hidden shadow-lg">
                            <div className="px-2 py-4">
                                <div className="font-bold text-xl mb-2 mt-8 text-black">
                                    { isLoading ?  <img alt="loading.." className="relative flex justify-center py-3 px-4 mx-auto w-auto" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                    :   info.length > 0 ? info[0].pending_inscription : 0
                                    } 
                                </div>
                                <p className="text-gray-700 text-xs">
                                    Inscripciones Pendientes
                                </p>
                            </div>
                        </div>
                </div>
                <div className="w-full md:w-1/4 p-4 text-center pl-0">
                        <div className="max-w-sm bg-white rounded overflow-hidden shadow-lg">
                            <div className="px-2 py-4">
                                <div className="font-bold text-xl mb-2 text-black mt-8">
                                   { isLoading ?  <img alt="loading.." className="relative flex justify-center py-3 px-4 mx-auto w-auto" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                    :   info.length > 0 ? info[0].pending_inscription_terceros : 0
                                    }
                                </div>
                                <p className="text-gray-700 text-xs">
                                    Inscripciones de Tercero Pendientes
                                </p>
                            </div>
                        </div>
                    </div>
                <div className="w-full md:w-1/4 p-4 text-center pl-0">
                        <div className="max-w-sm bg-white rounded overflow-hidden shadow-lg">
                            <div className="px-2 py-4">
                                <div className="font-bold text-xl mb-2 text-black mt-8">
                                    { isLoading ?  <img alt="loading.." className="relative flex justify-center py-3 px-4 mx-auto w-auto" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                    :   info[0] ?  info[0].pending_withdraw : 0
                                    }
                                </div>
                                <p className="text-gray-700 text-xs">
                                    Retiros pendientes
                                </p>
                            </div>
                        </div>
                    </div>
                <div className="w-full md:w-1/4 p-4 text-center pl-0">
                        <div className="max-w-sm bg-white rounded overflow-hidden shadow-lg">
                            <div className="px-2 py-4">
                                <div className="font-bold text-xl mb-2 text-black mt-8">
                                    -
                                </div>
                                <p className="text-gray-700 text-xs">
                                    -
                                </p>
                            </div>
                        </div>
                    </div>             
            </div> */}
            {/*           
            <div className="flex md:flex-row flex-wrap p-1 overflow-x-auto text-xs"> 
                <button className={"hover:bg-green-600 text-white font-bold py-1 px-4 rounded-full h-8 mr-1 mt-3 ml-2 " + ((!isActiveFilter && statuWithdraw === 'all' ) ? 'bg-green-600': 'bg-gray-500' )}  onClick={handleFilter} key="all" value="all">
                    <span>Todos</span>
                </button>
                <button className={"hover:bg-green-600 text-white font-bold py-1 px-4 rounded-full h-8 mr-1 mt-3 " + (isActiveFilter && statuWithdraw === "Creado"  ? 'bg-green-600': 'bg-gray-500' )} onClick={handleFilter} key="0" value="Creado">
                    <span>Creados</span>
                </button>
                <button className={"hover:bg-green-600 text-white font-bold py-1 px-4 rounded-full h-8 mr-1 mt-3 " + (isActiveFilter && statuWithdraw === "En proceso"  ? 'bg-green-600': 'bg-gray-500' )} onClick={handleFilter} key="1" value="En proceso">
                    <span>En proceso</span>
                </button>
                <button className={"hover:bg-green-600 text-white font-bold py-1 px-4 rounded-full h-8 mr-1 mt-3 " + (isActiveFilter && statuWithdraw === "Depositado"  ? 'bg-green-600': 'bg-gray-500' )} onClick={handleFilter} key="2" value="Depositado">
                    <span>Depositado</span>
                </button>
                <button className={"hover:bg-green-600 text-white font-bold py-1 px-4 rounded-full h-8 mr-1 mt-3 " + (isActiveFilter && statuWithdraw === "Consignado"  ? 'bg-green-600': 'bg-gray-500' )} onClick={handleFilter} key="3" value="Consignado">
                    <span>Consignado</span>
                </button>
                <button className={"hover:bg-green-600 text-white font-bold py-1 px-4 rounded-full h-8 mr-1 mt-3 " + (isActiveFilter && statuWithdraw === "Rechazado por el banco" ? 'bg-green-600': 'bg-gray-500' )} onClick={handleFilter} key="5" value="Rechazado por el banco">
                    <span>Rechazado por el banco</span>
                </button>
                <button className={"hover:bg-green-600 text-white font-bold py-1 px-4 rounded-full h-8 mr-1 mt-3 " + (isActiveFilter && statuWithdraw === "Fraude" ? 'bg-green-600': 'bg-gray-500' )} onClick={handleFilter} key="6" value="Fraude">
                    <span>Fraude</span>
                </button>
            </div>

            <div className="flex md:flex-row flex-wrap p-4">
                {error && 
                <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
                    <span className="block sm:inline">{msn}</span>
                </div>
                }             
                 <div className="w-full">
                    {
                        showBanner && infoWithdraws&& (
                            <Banner
                                title={'Proceso realizado'}
                                description={`Problemas con el banco ${infoWithdraws.total_prob_bank} se depositaron ${infoWithdraws.total_approved} retiros seleccionado se rechazaron ${infoWithdraws.total_rejected} retiros seleccionados`}
                                setShowBanner={setShowBanner}
                                error={false}
                            />
                        )
                    }
                    {
                        showBanner && errorWithdraws &&(
                            <Banner
                                title={'Proceso realizado'}
                                description={`Ah ocurrido un error, por favor intentelo mas tarde`}
                                setShowBanner={setShowBanner}
                                error={errorWithdraws}
                            />
                        )
                    }
                <DataTable
                    title="Retiros"
                    data={listWithdrawFilter}
                    columns={columns}
                    selectableRows
                    selectableRowsHighlight={true}
                    fixedHeader={true}
                    pagination={pagination}
                    paginationComponentOptions={paginationOptions}
                    progressPending={pending}
                    progressComponent={<Circular />}
                    onSelectedRowsChange={handleChange}
                    contextActions={contextActions()}
                    actions={contextActions()}

                />
                </div> 
            </div> */}
        </>
    )
}

export default Operacion;