import React, { useState, useEffect, useContext, useRef } from "react";



import axios from 'axios';
import ModalOtp from "./ModalOTP";
import { setStorigTime, FormatPhone } from '../../containers/common/functions';
import ToggleExample from "../../components/toggle"
import { AES } from 'crypto-js';
import AxioInstance from '../../axios/AxiosConfig';
import ReCAPTCHA from "react-google-recaptcha";
import ModalRecuperarPassword from "../../containers/layouts/ModalRecuperarPassword"
import { logDOM } from "@testing-library/react";
import logoPath from "../../helpers/logoPath";
import variablesDomain from "../../helpers/variablesDomain";

function Login(props) {
    const [username, setUsername] = useState('');
    const [code, setCode] = useState('');
    const [mascara, setMascara] = useState({});
    const [password, setPassword] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [errorMsgEmail, setErrorMsgEmail] = useState(null);
    const domain = window.location.hostname;

    const [modal, setModal] = useState(false);

    const [isToggled, setIsToggled] = useState(false);

    const handleToggle = () => {
        setIsToggled(!isToggled);
    };

    const [error, setError] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [ReachapV3, SetReachapV3] = useState(null);
    const [recaptchaValueV2, setRecaptchaValueV2] = useState("");
    const [recaptchaValue, setRecaptchaValue] = useState(null);

    const captchaRef = useRef(null);



    // Maneja el cambio de reCAPTCHA
    const handleRecaptcha = async (value) => {
        setRecaptchaValue(value);
    };
    useEffect(() => {
        localStorage.setItem('DEV', process.env.REACT_APP_API_URL);
    }, []);

    useEffect(() => {

        // Inicializa reCAPTCHA v3 con tu clave del sitio
        if (recaptchaValue == null && recaptchaValueV2 == "") {
            const script = document.createElement("script");
            script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_KEY_SITE}`;
            script.async = true;
            document.body.appendChild(script);

            script.onload = () => {
                // Cuando el script de reCAPTCHA se carga, solicita una puntuación
                window.grecaptcha.ready(() => {
                    window.grecaptcha.execute(process.env.REACT_APP_RECAPTCHA_KEY_SITE, { action: "submit" }).then(handleRecaptcha).catch(e => console.log(e))
                });
            };
        }
    }, [recaptchaValue]);

    const userValue = (e) => {
        if (username[0] === '(' || Number.parseInt(e[0])) {
            setUsername(FormatPhone(e));
        } else {
            setUsername(e);
        }
    };

    const constOTPRequest = (e) => {

        setIsLoading(true)


        AxioInstance.post(`/api/otp/`, {
            username: username.toString().toLowerCase().replace(/\D/g, ""),
            password: password,
        }).then((response) => {

            if (response.data.status == 400 || response.data.status == 401) {
                setErrorMsg(response.data.data.message)
                setError(true)
            }
            else if (response.data.status == 502) {
                setErrorMsg("Estamos presentado inconveniente, intentalo nuevamente..")
                setError(true)
            } else {
                setShowModal(true)
                setMascara(response.data.data)
            }
            setIsLoading(false)
        }).catch((error) => {

        })

    }
    const cambiarDEVorPROD = (e) => {

        if (e) {
            localStorage.setItem('DEV', process.env.REACT_APP_API_URL_DEV);

        } else {
            localStorage.setItem('DEV', process.env.REACT_APP_API_URL);

        }

    }
    function ConfirmarRecaptcha(e) {


        axios.post(`/api/recaptcha/`, {
            recaptchaToken: e,
        }).then((response) => {
            if (response.data.data.success === true) {
                SetReachapV3(true)
                return true
            } else {
                SetReachapV3(false)
                return false
            }

        }).catch((error) => {

            return false
        });
    };
    function password_recovery_request(e) {

        AxioInstance.post(`/api/password_recovery_request/`, {
            email: e,
        }).then((response) => {

            if (response.data.data.result === "success") {

                setErrorMsgEmail("")
            } else if (response.data.status >= 400 && response.data.status !== 500) {
                setErrorMsgEmail("Correo no encontrado")
            }
            else if (response.data.status == 500) {
                setErrorMsgEmail("Problemas con el servicio")
            }


        }).catch((error) => {

            return false
        });
    };

    const handleRecaptchaChangeV2 = (value) => {
        setRecaptchaValueV2(value);
    };




    const Confirmar = async (e) => {
        e.preventDefault()
        const dev = localStorage.getItem('DEV');
        if (recaptchaValueV2 == "") {
            const tokenAprove = await ConfirmarRecaptcha(recaptchaValue);
            if (tokenAprove === false && recaptchaValueV2 !== "") {
                setError("Por favor, completa la verificación reCAPTCHA.");
                setIsLoading(false);
                return;
            }

        }
        /*         if (username[0] === '(' && dev === "https://prod.cobru.co/") {
                    constOTPRequest(e);
                } */
        handleSubmit(e);

    };
    const handleSubmit = async (e) => {


        setShowModal(false);
        setSubmitted(true);
        setIsLoading(true);

        if (!(username && password)) {
            return;
        }
        let user = '';

        if (username[0] === '(') {
            user = username.toString().replace(/\D/g, "");
        } else {
            user = username;
        }
        const dev = localStorage.getItem('DEV');
        console.log(dev);

        let data = {};
        if (code === "") {
            data = {
                username: `${dev === "https://dev.cobru.co/" ? "" : "COLURS-"}` + user.toString().replace(/ /g, ""),
                password: password,



            };
        } else {
            data = {
                username: user.toString().replace(/ /g, ""),
                password: password,
                platform: "PANEL",
                code: code,
            };
        }





        AxioInstance
            .post(`/api/login`, { data })
            .then((response) => {
                if (response.data.status !== 200) {

                    if (response?.data?.status === 403) {

                        setError('Por favor, inténtelo más tarde.');
                    } else {
                        setError(response?.data?.data?.detail);
                    }
                    setCode('');
                    setIsLoading(false);
                } else {
                    localStorage.setItem('token', response.data.data.access);
                    localStorage.setItem('refreshToken', response.data.data.refresh);
                    localStorage.setItem('expiresAtC', new Date());
                    setIsLoading(true);
                    window.location.reload(false);
                }
            })
            .catch((error) => {
                setError("Estamos presentando inconvenientes, inténtalo nuevamente.");
                setIsLoading(true);
            });
        setRecaptchaValue(null)
    };

    useEffect(() => {
        console.log('domain', domain)
    }, [domain])


    return (
        <>
            {modal && <ModalRecuperarPassword setModal={setModal} password_recovery_request={(e) => password_recovery_request(e)} setError={setErrorMsgEmail} error={errorMsgEmail} />}
            {showModal && < ModalOtp handleSubmit={(e) => handleSubmit(e)} isLoading={isLoading} setShowModal={setShowModal} setCode={setCode} code={code} mascara={mascara} constOTPRequest={(e) => constOTPRequest(e)} />}

            <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8 backgroundColurs"  >
                <div className="max-w-md w-full">
                    <img className="mx-auto h-20 w-auto" src={logoPath} alt="Logo Cobru Pro" />
                    <form method="POST" className="mt-6" onSubmit={(e) => Confirmar(e)}>


                        <div className="rounded-md">
                            <div className="px-4 pb-4">
                                <input
                                    aria-label="Username"
                                    name="username"
                                    value={username}
                                    onChange={(e) => userValue(e.target.value)}
                                    type="text"
                                    required
                                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:z-10 sm:text-sm sm:leading-5 backgroundColursLayouts colursBorder colursMainWhite colursPlasholder"
                                    placeholder="Usuario"
                                />
                            </div>
                            <div className="-mt-px px-4 pb-4">
                                <input
                                    aria-label="Password"
                                    name="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    type="password"
                                    required
                                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:z-10 sm:text-sm sm:leading-5 backgroundColursLayouts colursBorder colursMainWhite colursPlasholder"
                                    placeholder="Contraseña"
                                />
                            </div>
                        </div>
                        <ToggleExample cambiarDEVorPROD={cambiarDEVorPROD} />
                        {ReachapV3 === false && <div className="w-full flex justify-center mb-4">
                            <ReCAPTCHA sitekey={process.env.REACT_APP_RECAPTCHA_KEY_SITE_V2} onChange={handleRecaptchaChangeV2} />
                        </div>}



                        <div className="px-4 pb-4">
                            <button


                                type="submit"
                                className={`group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-full  focus:outline-none active:${`backgroundGray`} transition duration-150 ease-in-out ${!isLoading && username !== "" && password !== "" ? `${'backgroundButton  text-black'} ` : "btn-retiros text-white"
                                    }`}
                            >
                                {isLoading ? "INGRESANDO..." : "INGRESAR"}
                            </button>
                            {<div className="relative flex justify-center py-3 px-4 mx-auto w-auto hover:text-trixel-green "><p style={{ color: "#a0aec0" }} onClick={() => setModal(true)} className=" text-xs cursor-pointer ">{"Olvidé mi contraseña"} </p></div>
                            }
                            {
                                errorMsg &&
                                <div className="relative flex justify-center py-3 px-4 mx-auto w-auto"><p className="text-red-500 text-xs italic">{errorMsg} </p></div>
                            }
                            {(error || !submitted) && (
                                <div className="relative flex justify-center py-3 px-4 mx-auto w-auto">
                                    <p className="text-red-500 text-xs italic">{error}</p>
                                </div>
                            )}
                        </div>
                    </form >

                </div >
            </div >
        </>
    );
}


export default Login;
