import React, { useEffect, useState, useContext, Fragment } from "react";


import Numeral from "numeral";
import axios from "axios";
import { FormatAmount, isEmpty, token, TitleCase } from "../common/functions";
import Modal from "../layouts/Modal";
import ModalConfirmar from "../layouts/ModalConfirmacion";
import ModalAddRetiros from "../layouts/ModalAddRetiros";
import NoView from "../layouts/NoView";
import _, { set } from "lodash";
//import { getAuthenticatedHeaders } from '../common/functions';
import { Button } from "@material-ui/core";
import Buttons from '@atlaskit/button';
import AxioInstance from '../../axios/AxiosConfig';

import { FORMAT } from '../../helpers';
import ModalDescripcionRetirar from "../layouts/ModalDescripcionRetirar"
import moment from "moment";
import CircularProgress from "@material-ui/core/CircularProgress";
import Banner from "../../components/Banner";
import { FunctionContext } from "../../context/FuntionsContext";
import { AuthContext } from "../../context/AuthContext";
import DataTable from "react-data-table-component";
import { orderBy } from "lodash";
import Select, { components } from "react-select";
import ModalHacerRetiro from '../layouts/ModalHacerRetiro'
import { FaSearch } from "react-icons/fa"
import { FiDownload } from "react-icons/fi"
import ModalDescripcionEfecty from "../layouts/ModalDescripcionEfecty";
import variablesDomain from "../../helpers/variablesDomain";




const Retirar = (props) => {
    const getPermissions = localStorage.getItem('userDetails')
    const [toggle, setToggle] = useState(0);
    const [errorTable, setErrorTable] = useState(false)
    const [openModalRetirar, setOpenModalRetirar] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const [openModal, setOpenModal] = useState(false)
    const [dataModal, setDataModal] = useState([]);
    const [openModalEfecty, setOpenModalEfecty] = useState(false)

    const [State, setState] = useState(null);
    const [numFilas, setNumFilas] = useState([]);
    //const totalBalance = props.totalBalance;
    const [bankAccount, setBankAccount] = useState('');
    const [montoRetirar, setmontoRetirar] = useState(0);
    const [bankAccounts, setBankAccounts] = useState([]);
    const [msnResponseWithDraw, setMsnResponseWithDraw] = useState([]);
    const [alerta, setAlerta] = useState(false);

    const [Bank, setBank] = useState('');
    const [BankAcount, setBankAcount] = useState('');
    const [BankType, setBankType] = useState(null);
    const [defaultBankActivate, setDefaultBankActivate] = useState(false);


    const [loadingModal, setLoadingModal] = useState(false);

    const [BankAccountsList, setBankAccountsList] = useState([]);



    const [dataModalConfirm, setdataModalConfirm] = useState([]);

    const [maximo, setMaximo] = useState(0);
    const [minimo, setMinimo] = useState(0);

    const [withdraws, setWithdraws] = useState([]);
    const [items, setItems] = useState([]);
    const [isSending, setIsSending] = useState(false);
    const [isActiveFilter, setIsActiveFilter] = useState(false);
    const [statusCobru, setStatusCobru] = useState(null);
    const [loadingWithdraws, setLoadingWithdraws] = useState(false);
    const [number, setNumber] = useState(0);
    const [totalBalance, setTotalBalance] = useState(props.totalBalance);
    //const minAmount = 5000;
    const minAmount = 10000;
    const [showModal, setShowModal] = useState(false);

    const [showModalConfirmar, setShowModalConfirmar] = useState(false);

    const [retiroPk, setRetiroPk] = useState("");

    const [reloadData, setReloadData] = useState(false);
    const [showBanner, setShowBanner] = useState(false);
    const [bannerError, setBannerError] = useState(false);
    const [loadingTable, setLoadingTable] = useState(true);

    const [bannerContent, setBannerContent] = useState({
        title: "",
        description: "",
    });
    const { getAuthenticatedHeaders, permissions } = useContext(AuthContext);
    const { showModalRetirar, setShowModalRetirar, setBuscarRetiro,
        buscarRetiro, buscar, setBuscador, opcionRetiros } = useContext(FunctionContext);
    const canViewWithdraw = permissions.permission_withdraw;
    //const canViewWithdraw = props.canViewWithdraw;
    const [state0, setState0] = useState(props.state0)
    const [state1, setState1] = useState(props.state1)
    const [state3, setState3] = useState(props.state3)
    const [amount0, setAmount0] = useState(0)
    const [amount1, setAmount1] = useState(0)
    const [amount3, setAmount3] = useState(0)
    //Pagination
    const [currentCobrus, setCurrentCobrus] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pagination, setpagination] = useState(true);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(50);
    const options = [
        { value: 'chocolate', label: 'Chocolate' },
        { value: 'strawberry', label: 'Strawberry' },
        { value: 'vanilla', label: 'Vanilla' },
        { value: 'vanilla', label: 'Vanilla' },
        { value: 'vanilla', label: 'Vanilla' },
        { value: 'vanilla', label: 'Vanilla' },
        { value: 'vanilla', label: 'Vanilla' },
        { value: 'vanilla', label: 'Vanilla' },
    ]
    const [userIp, setUserIp] = useState(false);
    const handleChangeBanks = async (event) => {
        let bancoSelect = {}


        event.preventDefault();
        if (event.target.value === "Open") {

            setShowModalRetirar(true)
        } else {

            bankAccounts.map(item => {


                if (item.pk == event.target.value) {

                    bancoSelect = item
                } else {
                    return null
                }

            })

            setBankAccount(bancoSelect)

        }


    };
    useEffect(() => {
        getState(0)
        getState(1)
        getState(3)
    }, [])
    function capitalize(word) {
        const lower = word.toLowerCase();
        return word.charAt(0).toUpperCase() + lower.slice(1);
    }
    const Nombre = (name) => {
        var espacio1 = name.indexOf(" ")
        var espacio2 = name.indexOf(" ", espacio1 + 1)

        if (espacio2 == -1) {
            return capitalize(name);
        } else {
            var nombre = name.substring(0, espacio2);


            return capitalize(nombre)
        }

    }
    const ActivarModal = (data) => {
        setDataModal(data)
        setOpenModal(true)
    }
    const ActivarModalEfecty = (data) => {
        setDataModal(data)
        setOpenModalEfecty(true)
    }
    useEffect(() => {
        lasfilas()
    }, [totalRows]);
    function esTextoONumero(valor) {
        return isNaN(valor);
    }

    useEffect(() => {
        if (buscar) {
            if (opcionRetiros != 3 && esTextoONumero(buscarRetiro) && opcionRetiros != 4) {
                window.alert('El campo solo acepta números');
            } else {
                fetchCobrus()
            }

        }
    }, [buscar])
    useEffect(() => {

        fetchCobrus()

    }, [State, toggle])



    const lasfilas = () => {

        if (totalRows <= 10) {
            setNumFilas([10])

        } else if (totalRows <= 25) {
            setNumFilas([10, 25,])
        } else if (totalRows <= 25) {
            setNumFilas([10, 25,])
        } else if (totalRows <= 50) {
            setNumFilas([10, 25, 50,])
        } else if (totalRows <= 100) {
            setNumFilas([10, 25, 50, 100,])
        } else if (totalRows <= 200) {
            setNumFilas([10, 25, 50, 100, 200,])
        } else if (totalRows <= 500) {
            setNumFilas([10, 25, 50, 100, 200, 500,])
        } else if (totalRows <= 1000) {
            setNumFilas([10, 25, 50, 100, 200, 500, 1000,])
        } else if (totalRows <= 1500) {
            setNumFilas([10, 25, 50, 100, 200, 500, 1000, 1500])
        } else if (totalRows <= 2000) {
            setNumFilas([10, 25, 50, 100, 200, 500, 1000, 1500, 2000])
        } else {
            setNumFilas([10, 25, 50, 100, 200, 500, 1000, 1500, 2000])
        }

    }
    useEffect(() => {
        ListaDeBancos()
    }, []);
    const handleChangeBankss = (event) => {
        event.preventDefault();


        setBank(event.target.value);

    };
    const getBalance = async () => {

        try {
            const response = await AxioInstance.post(`/api/getBalance`, {
                headers: await getAuthenticatedHeaders(),
            });

            if (response.data.status === 200) {
                let pendingBalance = "$0";
                let pendingSaved = "$0";
                let data = response.data.data;

                if (data.pendingBalance > 0) {
                    pendingBalance = FormatAmount(Math.round(data.pendingBalance));
                };
                if (data.saved > 0) {
                    pendingSaved = FormatAmount(Math.round(data.saved));
                };

                setTotalBalance({
                    balance: FormatAmount(data.balance),
                    pendingBalance: pendingBalance,
                    pendingSaved: pendingSaved
                })

            }

        } catch (error) {

        }
    };
    const ListaDeBancos = async () => {
        try {
            const response = await AxioInstance.post(`/api/bank_list_third_party_withdraw`, {
                headers: await getAuthenticatedHeaders(),
            });

            if (response.data.status === 200) {

                setBankAccountsList(response.data.data)

            }

        } catch (error) {

        }
    };


    const idBank = (id) => {
        let banco = ''
        if (id == 0) {
            banco = 'Bancolombia'
        } else if (id == 1) {
            banco = 'Banco Bogota'
        } else if (id == 2) {
            banco = ' Davivienda'
        } else if (id == 3) {
            banco = 'BBVA'
        } else if (id == 4) {
            banco = 'Banco Occidente'
        } else if (id == 5) {
            banco = 'Colpatria'
        } else if (id == 6) {
            banco = 'Banco Popular'
        } else if (id == 7) {
            banco = 'Helm Bank'
        } else if (id == 8) {
            banco = 'Banco Agrario'
        } else if (id == 9) {
            banco = 'Banco BCSC'
        } else if (id == 10) {
            banco = 'Banco CorpBanca'
        } else if (id == 11) {
            banco = 'Banco Citibank'
        } else if (id == 12) {
            banco = 'Banco AV Villas'
        } else if (id == 13) {
            banco = 'Banco ProCredit'
        } else if (id == 14) {
            banco = 'Banco Pichincha'
        } else if (id == 15) {
            banco = 'Banco Bancoomeva'
        } else if (id == 16) {
            banco = 'Banco Santander'
        } else if (id == 17) {
            banco = 'Banco Falabella'
        } else if (id == 18) {
            banco = 'Nequi'
        } else if (id == 19) {
            banco = 'Banco DaviPlata'
        } else if (id == 20) {
            banco = 'Banco Finandina'
        } else if (id == 21) {
            banco = 'Banco Itaú'
        } else if (id == 22) {
            banco = 'Banco Itaú CorpBanca'
        } else if (id == 23) {
            banco = 'Lulo Bank'
        } else if (id == 24) {
            banco = 'Coltefinanciera'
        } else {
            banco = ''
        }
        return banco
    }

    const optionValueSelectBank = () => {
        let items = [];
        for (let i = 0; i < bankAccounts.length; i++) {

            items.push(
                <option key={bankAccounts[i].pk} value={bankAccounts[i].pk}>
                    {Nombre(bankAccounts[i].account_holder_name)} - {idBank(bankAccounts[i].bank_name)} - {bankAccounts[i].account_type == 0 ? "Ahorro" : "Corriente"} - {bankAccounts[i].account_number}
                </option>
            );
        }

        return items;
    };
    useEffect(() => {
        axios.get(`https://ipapi.co/json/`)
            .then(res => setUserIp(res.data.ip))

    }, [userIp]);

    const GetMaxMinWithdraw = async () => {
        let business_constants = localStorage.getItem("business_constants");
        let constants = JSON.parse(business_constants);

        let minWithdraw = await _.find(constants, function (o) { return o.id == 17; });

        let maxWithdraw = await _.find(constants, function (o) { return o.id == 18; });


        setMaximo(maxWithdraw.int_value)
        setMinimo(minWithdraw.int_value)
    }

    const calcular = async (monto) => {

        let amount = monto.toString().replace(/\D/g, "")

        let maxmin = await Sacarimpuestos(parseInt(monto))

        let DataBanco = bankAccount
        /*  let IVA = maxmin.;
 
         let withdrawFee = maxmin;
 
 
         let fee = DataBanco.fee;
 
         let discount = this.state.cuponDiscount ? fee * this.state.cuponDiscount / 100 :  0;
 
         let final_fee = fee - discount;

 
         let dataCalculada = await {
             bankFeeIva: final_fee == 0 ? fee * IVA / 100 : final_fee * IVA / 100,
             bankFeePlusIVA: final_fee + final_fee * IVA / 100,
             cobruFee: withdrawFee[0],
             gmf: ((parseInt(amount) - (final_fee + final_fee * IVA / 100) - withdrawFee[0] - withdrawFee[1]) / 1000) * 4,
             cobruFeeIva: withdrawFee[1],
             withdrawFee: withdrawFee[2],
             finalAmount:
                 parseInt(amount) - (final_fee + final_fee * IVA / 100) - withdrawFee[0] - withdrawFee[1] - (((parseInt(amount) - (final_fee + final_fee * IVA / 100) - withdrawFee[0] - withdrawFee[1]) / 1000) * 4),
             discount: discount,
             costCalculated: true
         };
  */

        setdataModalConfirm({
            account_number: DataBanco.account_number,
            account_type: DataBanco.account_type,
            bank_name: DataBanco.bank_name,
            fee: maxmin.cobru_fee,
            get_account_type_display: DataBanco.get_account_type_display,
            get_bank_name_display: DataBanco.get_bank_name_display,
            owner: DataBanco.owner,
            pk: retiroPk,
            userIp: userIp,
            fee_iva: maxmin.cobru_fee_iva,
            gmf: maxmin.gmf,
            amount: amount,
            payed_amount: maxmin.total,
        })
        setShowModalConfirmar(true)

    }
    const Sacarimpuestos = async (monto) => {
        try {
            const response = await AxioInstance.post(`/api/estimate_thirdpartywithdraw`, {
                headers: await getAuthenticatedHeaders(),
                amount: parseInt(monto)

            });

            if (response.data.status === 200) {

                return response.data.data
            }

        } catch (error) {

        }
    }


    const GetCobruWithdrawFeeAndIVA = async (amount) => {

        let business_constants = localStorage.getItem("business_constants");
        let user_details = localStorage.getItem("userDetails");

        user_details = JSON.parse(user_details);

        business_constants = JSON.parse(business_constants);

        let cobruWithdrawFee = _.find(business_constants, function (o) { return o.id == 19; });

        if (user_details.withdraw_fee && user_details.withdraw_fee != -1) {
            cobruWithdrawFee.int_value = user_details.withdraw_fee;
        }
        let IVA = _.find(business_constants, function (o) { return o.id == 0; });

        let fee = (amount * cobruWithdrawFee.int_value) / 100;
        let withdrawIVA = (fee * IVA.int_value) / 100;






        return [fee, withdrawIVA, cobruWithdrawFee.int_value, IVA];


    }

    const handClickSendRequest = async (event) => {
        event.preventDefault();
        setIsSending(true);
        setShowBanner(false);
        setShowModalConfirmar(false)
        let banner = {};
        const amount = parseInt(number.toString().replace(/\D/g, ""));
        if (amount >= parseInt(minAmount)) {

            await sendRequestWithDraw(bankAccount, amount);
            setShowBanner(false)
        }
        else {

            banner = {
                title: "Saldo Insuficiente",
                description: "No tienes saldo suficiente, para realizar esta accion",

            };

            setBannerContent(banner);
            setShowBanner(true);
            setIsSending(false);
        }
        /* setIsSending(true);
                let bank_account = bankAccount;
                let amount = parseInt(number.toString().replace(/\D/g,""));
        
                if ( amount >= parseInt(minAmount )) {

                   sendRequestWithDraw(bank_account, amount);
                } */
    };
    const consultar = async (dataModal) => {
        setLoadingModal(true)
        setLoading(true);

        const data = {
            account_holder_name: dataModal.account_holder_name,
            account_type: parseInt(dataModal.account_type),
            account_holder_document: dataModal.account_holder_document.replace(/\./g, ''),
            account_holder_document_type: parseInt(dataModal.account_holder_document_type),
            account_number: dataModal.account_number,
            bank_name: parseInt(dataModal.bank_name)

        }

        try {
            const response = await AxioInstance.post(`/api/AddBank/`, {
                headers: await getAuthenticatedHeaders(),
                data
            });



            if (response.data.status === 201) {
                let status = response.data.statusText;
                if (status === "Created") {
                    setBankAccount(dataModal)
                    let banner = {
                        title: "Se agrego la cuenta",
                        description: `Se agrego la cuenta ${idBank(dataModal.bank_name)} - ${dataModal.account_number}`,
                    };

                    setShowBanner(true);
                    setBannerContent(banner);
                    setShowModalRetirar(false)
                    getUserListBanksRender(dataModal);
                }
                else {
                    let banner = {
                        title: "ups ah ocurrido un error",
                        description: "No se pudo agregar la cuenta",
                    };
                    setBannerError(true)
                    setShowBanner(true);
                    setBannerContent(banner);
                    setShowModalRetirar(false)
                }


            } else if (response.data.status === 400 && response.data.data == "Invalida numero de cuenta.") {
                let banner = {
                    title: "ups ah ocurrido un error",
                    description: response.data.data,
                };
                setBannerError(true)
                setShowBanner(true);
                setBannerContent(banner);
                setShowModalRetirar(false)
            }
            else if (response.data.status === 400 && response.data.data.non_field_errors == "La cuenta ya esta registrada en el sistema.") {
                let banner = {
                    title: "ups ah ocurrido un error",
                    description: "La cuenta ya esta registrada en el sistema.",
                };
                setBannerError(true)
                setShowBanner(true);
                setBannerContent(banner);
                setShowModalRetirar(false)
            }
            else {
                let banner = {
                    title: "ups ah ocurrido un error",
                    description: "No se pudo agregar la cuenta",
                };
                setBannerError(true)
                setShowBanner(true);
                setBannerContent(banner);
                setShowModalRetirar(false)
            }
        } catch (error) {

        }
        setLoading(false);
        setLoadingModal(false)

    };

    const handleChangeWithDrawAmount = (amountValue) => {

        let amount = parseInt(
            amountValue.target.value.toString().replace(/\D/g, "")
        );
        setmontoRetirar(amount)
        if (amount > minimo - 1) {
            setAlerta(false)
        }
        let current_balance = parseInt(totalBalance.toString().replace(/\D/g, ""));
        if (amount > current_balance) {
            amount = current_balance;

        } else if (amount < amountValue) {
            amount = amountValue;

        }
        let withdrawAmount = Numeral(amount).format("0,0[.]00").replace(/,/g, ".");
        setNumber(withdrawAmount);
    };
    const getUserListBanksRender = async (DataAgregada) => {
        try {
            const response = await AxioInstance.post(`/api/getListBankUser`, {
                headers: await getAuthenticatedHeaders(),
            });



            if (response.data.status === 200) {
                let data = response.data.data;

                setIsLoading(false)
                setBankAccounts(data);
                if (!!DataAgregada) {
                    setBankAccount(DataAgregada);
                } else {
                    setBankAccount(data.results[0].pk);
                }

                setLoading(false);
                setDefaultBankActivate(true);
            }
            setIsLoading(false)

        } catch (error) {

        }
    };

    /**
     * get user's bank list
     */
    const getUserListBanks = async () => {
        try {
            const response = await AxioInstance.post(`/api/getListBankUser`, {
                headers: await getAuthenticatedHeaders(),
                page: 1
            });



            if (response.data.status === 200) {
                let data = response.data.data.results;

                setIsLoading(false)
                setBankAccounts(data);


            }
        } catch (error) {

        }
    };

    /**
     * get user's withdraws list
     */



    const handleFilter = (event) => {
        let filter = event.currentTarget.value;
        if (filter === State) {
            setToggle(toggle + 1);
        } else {

            let items = 0;
            let activeFilter = true;
            setStatusCobru(filter)
            setState(filter)
            getBalance()
            setCurrentCobrus(items);
            setStatusCobru(filter);
            setIsActiveFilter(activeFilter);
        }
    };

    /**
     * Send withdraw balance request
     * @param {int} bank_account Id for the account's
     * @param {int} amount Amount to withdraw
     */
    const sendRequestWithDraw = async (bank_account, amount) => {
        try {

            const data = {
                account_holder_name: bank_account.account_holder_name,
                account_type: bank_account.account_type,
                account_holder_document_type: bank_account.account_holder_document_type,
                account_holder_document: bank_account.account_holder_document,
                account_number: bank_account.account_number,
                bank_name: bank_account.bank_name,
                override: true,
                amount: amount,
                latitude: userIp.latitude,
                longitude: userIp.longitude,
                platform: "PANEL",

            }

            let banner = {};
            setShowModal(false);
            const response = await AxioInstance.post(`/api/request/withdraw`, {
                data,
                headers: await getAuthenticatedHeaders(),
            });

            if (response.data.data.success === true && response.data.status === 201) {
                setMsnResponseWithDraw(response.data.data);
                setRetiroPk(response.data.data.id)
                setBankAccount('')
                setNumber(0)
                setIsSending(false);
                setReloadData(!reloadData);
                setShowBanner(false);
                getBalance()
                setShowModal(true);
            } else {
                setShowBanner(true);
                setIsSending(false);
                banner = {
                    title: "Ocurrio un error",
                    description: "Ah ocurrido un error",
                };
                if (response.data.status === 500) {
                    banner = {
                        title: "Ocurrio un error",
                        description: `Ah ocurrido un error -  ${response.data.statusText}`,
                    };
                }
                setBannerContent(banner);
            }
        } catch (error) {

            let banner = {
                title: "Ocurrio un error server",
                description: "Ah ocurrido un error server",
            };
            setShowBanner(true);
            setBannerContent(banner);
            setIsSending(false);
        }
    };

    /* const getBalance =  async () => {
            try {
                const response = await axios.post(`/api/getBalance`, {
                    headers : await getAuthenticatedHeaders(),
                  });

                  setTotalBalance(9255645);
            } catch (error) {

            }
        } */

    useEffect(() => {
        getUserListBanks();
        GetMaxMinWithdraw()
        getUserListBanksALL();

    }, []);

    useEffect(() => {
        //getUserWithDraws();
        fetchCobrus(1);
    }, [reloadData]);

    useEffect(() => {
        getBalance()
    }, [reloadData])

    //data table columns
    const handleSort = (column, sortDirection) => {
        // simulate server sort
        //setLoading(true);

        // instead of setTimeout this is where you would handle your API call.
        setTimeout(() => {
            getBalance()
            setCurrentCobrus(orderBy(currentCobrus, column.selector, sortDirection));
            //setLoading(false);
        }, 100);
    };
    const Cancelar = async (e) => {
        let banner = {};

        try {
            const response = await AxioInstance.post(`/api/cancelthirdpartywithdraw/`, {
                id: e,
                headers: await getAuthenticatedHeaders(),
            });

            if (response.data.status === 200) {
                banner = {
                    title: "Cancelado",
                    description: "El retiro ha sido cancelado",
                };
                setBannerContent(banner);
                setShowBanner(true);
                setBannerError(false);
                setReloadData(!reloadData);
                setStatusCobru(1);
            } else {
                banner = {
                    title: "Cancelado",
                    description: "El retiro no ha sido cancelado",
                };
                setBannerContent(banner);
                setBannerError(false);
                setShowBanner(true);
            }
        } catch (error) {

            return [];
        }
    };

    const columns = [
        {
            name: "REF",
            sortable: true,
            selector: "pk",
            grow: 1,
            cell: (row) => (
                <div
                    className="flex-row flex items-center justify-start  "
                    title={
                        row.state === 0 || row.state === 1
                            ? "Pendiente"
                            : row.state === 2
                                ? "Depositado"
                                : row.state === 3
                                    ? "Consignado"
                                    : row.state === 4 || row.state === 5 || row.state === 6
                                        ? "Rechazado"
                                        : row.state === 7
                                            ? "Reversado"
                                            : ""
                    }
                >
                    <div
                        className={
                            "rounded-full h-4 w-4 " +
                            (row.state === 0 || row.state === 1
                                ? "bg-yellow-500"
                                : row.state === 2
                                    ? "bg-green-500"
                                    : row.state === 3
                                        ? "orange"
                                        : row.state === 4 || row.state === 5 || row.state === 6
                                            ? "Color-red"
                                            : row.state === 7
                                                ? "Color-red"
                                                : "")
                        }
                    ></div>
                    <div className='ml-2 links cursor-pointer' onClick={() => {
                        if (row?.type === 'cash_withdraw') {
                            ActivarModalEfecty(row)
                        } else {

                            ActivarModal(row)
                        }
                    }}
                        style={{ fontWeight: 700 }}>
                        {row.pk}
                    </div>
                </div>
            ),
        },
        {
            name: "NOMBRE",
            sortable: true,
            selector: "payer_name",
            grow: 3,
            cell: (row) => (
                <div className="colursMainWhite py-3 flex items-center  ">
                    <span> {row.payer_name}</span>
                </div>
            ),
        },
        {
            name: "CUENTA",
            sortable: true,
            selector: "account_number",
            grow: 1,
            cell: (row) => (
                <div className="colursMainWhite py-3 flex items-center  cursor-pointer">
                    <span> {row.account_number}</span>
                </div>
            ),
        },
        {
            name: "BANCO",
            sortable: true,
            selector: "account_bank",
            grow: 1,
            cell: (row) => (
                <div
                    className="colursMainWhite py-3 flex items-center"
                    style={{ marginLeft: "-7px" }}
                >
                    {
                        row?.type === 'cash_withdraw' ?
                            'Efecty'
                            :
                            <span> {row.account_bank === "Banco Cooperativo Coopcentral" ? "Banco Coopcentral" : row.account_bank}</span>
                    }
                </div>
            ),
        },
        {
            name: "DEBITADO",
            sortable: true,
            selector: "amount",
            grow: 1,
            cell: (row) => (
                <div
                    className="colursMainWhite py-3 flex items-center"
                    style={{ marginLeft: "-7px" }}
                >
                    <span>
                        {` ${FormatAmount(row.amount, 1)}`}<span style={{ fontSize: 9 }}>{`${FormatAmount(row.amount, 2)}`}</span>
                    </span>

                </div>
            ),
        },
        {
            name: "ABONADO",
            sortable: true,
            selector: "payed_amount",
            grow: 1,
            cell: (row) => (
                <div className="colursMainWhite py-3 flex items-center">
                    <span>
                        {` ${FormatAmount(row.payed_amount, 1)}`}<span style={{ fontSize: 9 }}>{`${FormatAmount(row.payed_amount, 2)}`}</span>
                    </span>

                </div>
            ),
        },
        {
            name: "FECHA",
            grow: 2,
            cell: (row) => (
                <div className="colursMainWhite py-3 flex items-center">
                    <span className="colursMainWhite py-3 ml-3 flex items-center">
                        {" "}
                        {<DateFormat withdraw={row} />}{" "}
                    </span>
                </div>
            ),
        },
        {

            name: "ACCIONES",
            grow: 2,
            wrap: true,
            cell: (row) => (
                <div className=" py-3 flex items-center">

                    {row.state === 0 && row.type === "third_party_withdraw" && (
                        <Button
                            onClick={() => Cancelar(row.pk)}
                            className="ButtonCancelarColurs text-white"
                        >
                            Cancelar
                        </Button>
                    )}
                </div>
            ),
        },

    ];

    const customStyles = {
        table: {
            style: {
                backgroundColor: '#201f26', // Color de fondo de toda la tabla
            },
        },
        header: {
            style: {

                minHeight: "45px",

            },
        },
        headRow: {
            style: {

                backgroundColor: "#201f26",
                whiteSpace: "nowrap",
                fontSize: "0.75rem",
                textAlign: "left",
            },
        },
        headCells: {
            style: {

                color: '#fff',

                paddingLeft: "3rem",
                paddingRight: "3rem",
            },
        },
        rows: {
            style: {
                backgroundColor: "#201f26",

            },
        },
        cells: {
            style: {

                backgroundColor: "#201f26",
                borderStyle: "dashed",
                borderTopWidth: "1px",
                borderColor: "#8a8a8e",
                whiteSpace: "nowrap",
                paddingLeft: "3rem",
                paddingRight: "3rem",

            },
        },
        pagination: {
            style: {
                backgroundColor: '#201f26', // Cambia esto al color que prefieras
                color: '#fff', // Cambia esto al color que prefieras
            },
            pageButtonsStyle: {
                color: '#fff', // Cambia esto al color que prefieras
            },
            pageButtonsStyle: {
                color: '#fff', // Cambia esto al color que prefieras
                // Cambia esto al color que prefieras para los iconos
            },
            pageButtonsStyle: {
                color: "#fff",
                fill: "#fff"
            }

        },
        noData: {
            style: {
                backgroundColor: '#201f26', // Cambia este color al que prefieras
                color: 'white', // Opcional, para cambiar el color del texto
                textAlign: 'center', // Alinear el texto al centro
                padding: '20px', // Agregar un poco de padding
            },
        },
        progress: {
            style: {
                backgroundColor: '#201f26', // Cambia este color al que prefieras
                color: 'black', // Opcional, para cambiar el color del texto
                textAlign: 'center', // Alinear el texto al centro
                padding: '20px', // Agregar un poco de padding
            },
        },
    };

    const refreshRequest = () => {
        setLoading(true)
        setTimeout(() => {
            fetchCobrus(1);
            getBalance();
        }, 5000);
    }
    //server side pagination
    const fetchCobrus = async (page) => {

        var withdraw_id = ''
        var document = ''
        var description = ""
        let idempotency_key = null
        setLoading(true);
        setLoadingTable(true);
        if (opcionRetiros == 1) {
            withdraw_id = buscarRetiro
        } else if (opcionRetiros == 3) {
            description = buscarRetiro
        } else if (parseInt(opcionRetiros) === 4) {
            idempotency_key = buscarRetiro
        }
        else {
            document = buscarRetiro
        }

        const response = await AxioInstance.post(`/api/thirdpartywithdraw`, {
            headers: await getAuthenticatedHeaders(),
            pageNum: !!page ? page : 1,
            perPage: perPage,
            document_number: document,
            withdraw_id: withdraw_id,
            state: State,
            description: description,
            idempotency_key
        });

        if (response.data.status === 200) {

            const withdraws = response.data.data.data.data;

            getBalance()
            setCurrentCobrus(withdraws);
            setWithdraws(withdraws);
            setTotalRows(response.data.data.data.count);
            setLoading(false);
        }
        else if (response.data.status === 400) {
            setErrorTable(true)
        }
        setLoading(false);
        setLoadingTable(false);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        setLoading(true);

        const response = await AxioInstance.post(`/api/thirdpartywithdraw`, {
            headers: await getAuthenticatedHeaders(),
            pageNum: page,
            perPage: newPerPage,
            document_number: null,
            withdraw_id: null,
        });


        if (response.data.status === 200) {
            const withdraws = response.data.data.data.data;;


            //setCurrentCobrus(final);
            setCurrentCobrus(withdraws);
            setWithdraws(withdraws);
            setPerPage(newPerPage);
            setLoading(false);
        }
        else if (response.data.status === 400) {
            setErrorTable(true)
        }
        setLoading(false);
    };

    const handlePageChange = (page) => {
        fetchCobrus(page);
    };
    const getUserListBanksALL = async () => {
        try {
            const response = await AxioInstance.post(`/api/get_banks/`, {
                headers: await getAuthenticatedHeaders(),
            });

            /*  if (response.data.status === 200) {
                 let data = response.data.data;

                 setBankAccountsList(data);
                 setBankAccount(data[0].bankName);
             } */
        } catch (error) {

        }
    };

    const optionValueSelectBanks = () => {
        let items = []

        for (let i = 0; i < BankAccountsList.length; i++) {

            items.push(
                <option key={BankAccountsList[i].id}
                    value={(BankAccountsList[i].id)}>
                    {TitleCase(BankAccountsList[i].name)}
                </option>
            );
        }
        return items;
    };
    const Option = (props) => {
        return (
            <Fragment>
                <components.Option style={{ zIndex: 9990 }}{...props}>{props.children}</components.Option>
            </Fragment>
        );
    };
    function formatearMontoEnPesos(monto) {
        // Convertir el monto a un número y asegurarnos de que sea válido
        const montoNumerico = parseFloat(monto);
        if (isNaN(montoNumerico)) {
            return "Monto inválido";
        }

        // Redondear el monto a dos decimales
        const montoRedondeado = montoNumerico.toFixed(2);

        // Verificar si el monto es igual a 0.00
        if (montoRedondeado === "0.00") {
            return "0";
        }

        // Eliminar ceros a la derecha si existen
        const montoFormateado = montoRedondeado.replace(/\.?0+$/, '');

        // Agregar separadores de miles y el símbolo de peso colombiano
        const separadorMiles = ".";
        return "$ " + montoFormateado.replace(/\B(?=(\d{3})+(?!\d))/g, separadorMiles);
    }

    const getState = async (state) => {

        try {
            const response = await AxioInstance.post(`/api/get_state/`, {
                headers: await getAuthenticatedHeaders(),
                state: state
            });
            if (response.data.status === 200) {
                if (response.data.data.data[0].state === 0) {
                    setAmount0(response.data.data.data[0].total_amount)
                    setState0(response.data.data.data[0].total_count);
                }
                else if (response.data.data.data[0].state === 1) {
                    setAmount1(response.data.data.data[0].total_amount)
                    setState1(response.data.data.data[0].total_count);
                } else {
                    setAmount3(response.data.data.data[0].total_amount)
                    setState3(response.data.data.data[0].total_count);
                }

            }

        } catch (error) {

        }
    };

    const GenerarCSV = async (state) => {
        setLoading(true);

        const response = await AxioInstance.post(`/api/report_by_state_in_csv`, {
            headers: await getAuthenticatedHeaders(),
            state: state,

        });



        if (response.data.status === 200) {
            let banner = {
                title: "",
                description: `Se enviara el archivo a ${JSON.parse(getPermissions).email}`,
            };
            setBannerContent(banner);
            setBannerError(false)
            setShowBanner(true);
            //setCurrentCobrus(final);

        }
        else {
            let banner = {
                title: "ups ah ocurrido un error",
                description: "ups ah ocurrido un error",
            };
            setBannerContent(banner);
            setBannerError(false)
            setShowBanner(true);

        }

        setLoading(false);
    };

    return canViewWithdraw === 1 || canViewWithdraw === 3 ? (

        <>
            {showModalRetirar &&
                <ModalAddRetiros refreshRequest={() => refreshRequest()} FORMAT={FORMAT} handleChangeBankss={handleChangeBankss} showModalAgg={showModalRetirar} setShowModalAgg={setShowModalRetirar} setBank={setBank} Bank={Bank} BankAccountsList={BankAccountsList} bankAccount={bankAccount} setBankAccount={setBankAccount} optionValueSelectBanks={optionValueSelectBanks} BankType={BankType} setBankType={setBankAcount} setBankAcount={setBankAcount} loading={loading} consultar={consultar} number={number} setBannerError={setBannerError} setShowBanner={setShowBanner} setBannerContent={setBannerContent} />
            }
            {
                (
                    <div className="flex md:flex-row flex-wrap p-3 justify-between">

                        <div className="w-full md:w-1/4 p-4 text-center pl-0">
                            <div className="max-w-sm backgroundColursLayouts rounded overflow-hidden shadow-lg">
                                <div className="px-2 py-4">
                                    <p className="colursMainWhite text-sm">
                                        <p className="colursMainWhite text-sm">Saldo Disponible</p>
                                    </p>
                                    <div className="font-bold text-xl mb-4 mt-4 colursMainWhite">
                                        {totalBalance.balance}
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="w-full md:w-1/4 p-4 text-center pl-0">
                            <div className="max-w-sm backgroundColursLayouts rounded overflow-hidden shadow-lg">
                                <div className="px-2 py-4">
                                    <p className="colursMainWhite text-sm">
                                        {`${state0} pendiente`}
                                    </p>
                                    <div className="font-bold text-xl mb-4 mt-4 colursMainWhite">
                                        {FormatAmount(amount0)}
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="w-full md:w-1/4 p-4 text-center pl-0">
                            <div className="max-w-sm backgroundColursLayouts rounded overflow-hidden shadow-lg">
                                <div className="px-2 py-4">
                                    <p className="colursMainWhite text-sm">
                                        {`${state1} en proceso`}
                                    </p>
                                    <div className="font-bold text-xl mb-4 mt-4 colursMainWhite">
                                        {FormatAmount(amount1)}
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="w-full md:w-1/4 p-4 text-center pl-0">
                            <div className="max-w-sm backgroundColursLayouts rounded overflow-hidden shadow-lg">
                                <div className="px-2 py-4">
                                    <p className="colursMainWhite text-sm">
                                        {`${state3} consignados`}
                                    </p>
                                    <div className="font-bold text-xl mb-4 mt-4 colursMainWhite">
                                        {FormatAmount(amount3)}
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="w-full md:w-12/12  py-2 text-center colursMainWhite">
                            <div className="rounded overflow-hidden shadow-lg backgroundColursLayouts">
                                <div className="px-2 py-3">
                                    <p className="font-body text-lg text-white text-left py-2 ml-12">
                                        Realiza un Retiro
                                    </p>
                                    <form className="w-full ">
                                        <div className="flex flex-wrap -mx-3 mb-2 items-center justify-center">
                                            <div className="w-full md:w-3/12 px-3 mb-6 md:mb-0">
                                                <input
                                                    className="appearance-none block w-full backgroundColursLayouts colursMainWhite border colursBorder rounded py-3 px-4 leading-tight focus:outline-none focus:colursBorder focus:colursBorder"
                                                    id="withdraw_amount"
                                                    value={"$" + number}
                                                    onChange={handleChangeWithDrawAmount}
                                                    type="tel"
                                                    keyboard="numeral"
                                                    placeholder="0"
                                                    pattern="[0-9]*"

                                                ></input>


                                            </div>
                                            <div className="w-full md:w-5/12 px-3 mb-6 md:mb-0 ">
                                                <Buttons
                                                    classID="focus-button-retirar"
                                                    className="backgroundColursLayouts colursBorder border"
                                                    style={{
                                                        height: 46,
                                                        alignItems: 'center',
                                                        width: '100%',
                                                        textAlign: 'left',
                                                        display: 'flex',
                                                        color: 'white', // Cambiar el color del texto a blanco
                                                        border: '1px solid black', // Agregar borde de 1px
                                                    }}
                                                    iconAfter={
                                                        <svg
                                                            width="24"
                                                            height="24"
                                                            viewBox="0 0 24 24"
                                                            focusable="false"
                                                            role="presentation">
                                                            <path
                                                                d="M8.292 10.293a1.009 1.009 0 0 0 0 1.419l2.939 2.965c.218.215.5.322.779.322s.556-.107.769-.322l2.93-2.955a1.01 1.01 0 0 0 0-1.419.987.987 0 0 0-1.406 0l-2.298 2.317-2.307-2.327a.99.99 0 0 0-1.406 0z"
                                                                fill="white"
                                                                fillRule="evenodd"
                                                            />
                                                        </svg>
                                                    }
                                                    onClick={() => setOpenModalRetirar(true)}
                                                >
                                                    <span style={{ color: 'white', }}> {/* Asegúrate de aplicar estilos también aquí */}
                                                        {bankAccount ? `${bankAccount.account_holder_name}- ${bankAccount.account_number}` : 'Buscar cuentas'}
                                                    </span>
                                                </Buttons>



                                            </div>
                                            <div className="w-full md:w-1/4 px-2 mb-6 md:mb-0">


                                                {parseFloat(montoRetirar) > parseFloat(minimo - 1) && parseFloat(montoRetirar) <= parseFloat(totalBalance.balance.replace(/[$.]/g, '')) ? (<button
                                                    className={`w-full flex-shrink-0 backgroundButton  text-sm border-4 border-white text-black py-2 rounded-full `}
                                                    type="button"
                                                    onClick={() => calcular(montoRetirar)}
                                                >
                                                    {isSending ? "Enviando..." : "Solicitar Retiro"}
                                                </button>
                                                ) : (<button
                                                    className="w-full flex-shrink-0  text-sm border-4 text-white py-2 rounded-full btn-retiros button-outline-off"
                                                    type="button"

                                                    onClick={() => setAlerta(true)}
                                                >
                                                    {isSending ? "Enviando..." : "Solicitar Retiro"}
                                                </button>)}

                                            </div>

                                        </div>
                                        {alerta && parseFloat(montoRetirar) < minimo &&
                                            <p className="text-red-500 text-xs italic p-2 items-center justify-start">el monto minimo para Retirar es ${minimo}</p>
                                        }
                                        {parseFloat(montoRetirar) > parseFloat(totalBalance.balance.replace(/[$.]/g, '')) &&
                                            <p className="text-red-500 text-xs italic p-2">balance insuficiente</p>
                                        }
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
            {
                showBanner && bannerContent && (
                    <div className="p-5">
                        <Banner
                            title={bannerContent.title}
                            error={bannerError}
                            description={bannerContent.description}
                            setShowBanner={setShowBanner}
                        />
                    </div>
                )
            }

            <div className="flex md:flex-row flex-wrap p-1 overflow-x-auto text-xs">
                <button
                    className={
                        `button-outline-off  font-bold py-1 px-4 rounded-full h-8 mr-1 mt-3 ml-2 ` +
                        ((statusCobru === null || statusCobru === "")
                            ? `text-black bg-white `
                            : "text-white backgroundColursLayouts")
                    }
                    onClick={handleFilter}
                    key="999"
                    value={null}
                >
                    <span>Todos</span>
                </button>
                <button
                    className={
                        `button-outline-off font-bold py-1 px-4 rounded-full h-8 mr-1 mt-3 ml-2 ` +
                        (isActiveFilter && statusCobru === '0'
                            ? `text-black bg-white `
                            : "text-white backgroundColursLayouts")
                    }
                    onClick={handleFilter}
                    key="0"
                    value="0"
                >
                    <span>Pendientes</span>
                </button>
                <button
                    className={
                        `button-outline-off  font-bold py-1 px-4 rounded-full h-8 mr-1 mt-3 ml-2 ` +
                        (isActiveFilter && statusCobru === '3'
                            ? `text-black bg-white `
                            : "text-white backgroundColursLayouts")
                    }
                    onClick={handleFilter}
                    key="3"
                    value="3"
                >
                    <span>Consignados</span>
                </button>
                <button
                    className={
                        `button-outline-off  font-bold py-1 px-4 rounded-full h-8 mr-1 mt-3 ml-2 ` +
                        (isActiveFilter && statusCobru === '2'
                            ? `text-black bg-white `
                            : "text-white backgroundColursLayouts")
                    }
                    onClick={handleFilter}
                    key="2"
                    value="2"
                >
                    <span>Depositados</span>
                </button>
                <button
                    className={
                        `button-outline-off  font-bold py-1 px-4 rounded-full h-8 mr-1 mt-3 ml-2 ` +
                        (isActiveFilter && statusCobru === '5'
                            ? `text-black bg-white `
                            : "text-white backgroundColursLayouts")
                    }
                    onClick={handleFilter}
                    key="5"
                    value="5"
                >
                    <span>Rechazados</span>
                </button>
            </div>
            {openModal === true &&
                <ModalDescripcionRetirar modal={() => setOpenModal()} open={openModal} data={dataModal} />
            }
            {openModalEfecty === true &&
                <ModalDescripcionEfecty modal={() => setOpenModalEfecty()} open={openModalEfecty} data={dataModal} />
            }
            {
                (
                    <div style={{ marginTop: "20px" }}>

                        <DataTable
                            data={currentCobrus}
                            columns={columns}
                            customStyles={customStyles}
                            noHeader={true}
                            fixedHeader={true}
                            highlightOnHover={true}
                            pagination={pagination}
                            noDataComponent={errorTable ? "Ups ah ocurrido un error" : "No hay movimientos disponibles"}

                            paginationServer
                            paginationTotalRows={totalRows}
                            paginationPerPage={perPage}
                            paginationRowsPerPageOptions={numFilas}
                            paginationComponentOptions={{
                                paginationClassName: 'custom-pagination',
                                noRowsPerPage: false,
                                rowsPerPageText: "Filas por página",
                                rangeSeparatorText: "de",
                            }}
                            onSort={handleSort}


                            onChangeRowsPerPage={handlePerRowsChange}
                            onChangePage={handlePageChange}
                            progressPending={loading}
                            progressComponent={
                                <div className="flex md:flex-row flex-wrap p-4">
                                    <div className="w-full">
                                        <div style={{ height: "50vh" }}>
                                            <div className="h-full w-full flex items-center justify-center">
                                                <CircularProgress size={75} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        />
                    </div>
                )
            }
            {/* {
                loadingTable == true && (
                    <div className="flex md:flex-row flex-wrap p-4">
                        <div className="w-full">
                            <div style={{ height: "50vh" }}>
                                <div className="h-full w-full flex items-center justify-center">
                                    <CircularProgress size={75} />
                                </div>
                            </div>
                        </div>
                    </div>
                )
            } */}
            {openModalRetirar && <ModalHacerRetiro setShowModalAgg={setOpenModalRetirar} setBank={setBankAccount} Bank={bankAccount} bankAccounts={bankAccounts} setBankAccounts={setBankAccounts} isLoading={isLoading} setIsLoading={setIsLoading} setShowModalRetirar={setShowModalRetirar} />}
            {showModalConfirmar ? <ModalConfirmar confirmar={(event) => handClickSendRequest(event)} info={dataModalConfirm} modal={() => setShowModalConfirmar()} /> : ""}
            {showModal ? <Modal retiroPk={retiroPk} info={dataModalConfirm} modal={true} /> : ""}
        </>
    ) : (
        <NoView />
    );
};
const DateFormat = ({ withdraw }) => {
    const [state, setstate] = useState("");

    useEffect(() => {
        if (withdraw) {
            getDate(withdraw);
        }
    }, [withdraw]);
    const getDate = async (withdraw) => {

        let date;

        if (withdraw.state == 3) {
            date = withdraw.date_consigned;
        } else if (withdraw.state == 2) {
            date = withdraw.date_deposited;
        } else if (withdraw.state == 5 || withdraw.state == 6) {
            date = withdraw.date_rejected;
        } else {
            date = withdraw.date_created;
        }
        setstate(date);
        return;
    };
    return (
        <>
            <div className="colursMainWhite py-3 flex items-center">
                <span className="colursMainWhite py-3 flex items-center">
                    {moment(state).format('DD/MM/YYYY HH:mm A')}
                </span>
            </div>
        </>
    );
};

export default Retirar;