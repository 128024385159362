import Logo from '../assets/images/cobru-logo.png';
import LogoColurs from '../assets/images/colurs.png';

import LogoMutu from '../assets/images/MutuLogo.png';
import LogoEzy from '../assets/images/ezytransfer-logo.png';




const domain = window.location.hostname;
const mutuDomain = 'panel.mutuahorro.com'
/*  */
const ezyDomain = 'localhost'
const ColursDomain = 'portal.colurs.io'

let logoExport = Logo
switch (domain) {
    case mutuDomain:
        logoExport = LogoMutu
        break;
    case ezyDomain:
        logoExport = LogoColurs
        break;
    case ColursDomain:
        logoExport = LogoColurs
        break;
    default:
        logoExport = Logo
        break;
}


export default logoExport