import React, { useState, useContext, useRef } from 'react';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { BsFillCalendarFill } from "react-icons/bs";
import moment from 'moment';
import { localeDataPicker } from '../common/functions';
import axios from 'axios';
import Banner from '../../components/Banner';
import { CSVLink } from "react-csv";
import NoView from '../layouts/NoView';
import { AuthContext } from '../../context/AuthContext';
import useGeolocation from "react-hook-geolocation";
import Numeral from "numeral";
import ModalDescripcionDebitar from '../../containers/layouts/ModalDescripcionDebitar'
import AxioInstance from '../../axios/AxiosConfig';

import { setStorigTime, FormatPhone } from '../../containers/common/functions';
import { useEffect } from 'react';
//import { getAuthenticatedHeaders} from '../common/functions';

const Debitar = (props) => {
    const buttonClickRef = useRef(false);
    const DEV = localStorage.getItem('DEV');

    const [aliasFrom, SetAliasFrom] = useState("")
    const [aliasTo, SetAliasTo] = useState("")
    const [dataModal, setDataModal] = useState("")
    const [Modal, setModal] = useState(false)

    const [number, setNumber] = useState(0)
    const [msg, setMsg] = useState('')
    const { latitude, longitude } = useGeolocation();
    const getPermissions = localStorage.getItem('userDetails')
    const [montoEnviar, setmontoEnviar] = useState(0)
    const [showBanner, setShowBanner] = useState(false);
    const [errorBanner, setErrorBanner] = useState(false);
    const [data, setData] = useState([]);
    const [usernameFrom, setUsernameFrom] = useState('');
    const [usernameTo, setUsernameTo] = useState('');
    const [amount, setAmount] = useState("");
    const [description, setDescripcion] = useState('');

    const [filterCurrency, setFilterCurrency] = useState('COP')

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);
    const [value, setValue] = useState("transactions");
    const [start_date, setStart_date] = useState(moment().startOf('month'));
    const [end_date, setEnd_date] = useState(moment().endOf('month'));
    const ranges = {
        'Ayer': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Últimos 7 Dias': [moment().subtract(6, 'days'), moment()],
        'Últimos 30 Días': [moment().subtract(29, 'days'), moment()],
        'Último Mes': [moment().startOf('month'), moment().endOf('month')],
        'Mes Anterior': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    };
    const locale = localeDataPicker;
    const maxDate = moment();
    const [label, setLabel] = useState("");
    const csvLink = React.useRef();
    const { getAuthenticatedHeaders, permissions } = useContext(AuthContext)
    const [descripcionValue, setDescripcionValue] = useState(null)
    //const canViewReport = props.canViewReport;
    const canViewReport = permissions.permission_reports;

    React.useEffect(() => {
        let start = start_date.format('MMM D YYYY');
        let end = end_date.format('MMM D YYYY');
        let label = start + ' - ' + end;
        if (start === end) {
            label = start;
        }
        setLabel(label);

    }, [start_date, end_date]);


    const handleEventSelect = (event) => {
        setValue(event.target.value);
    }

    const handleEvent = (event, picker) => {
        if (event.type === 'apply') {
            setStart_date(picker.startDate);
            setEnd_date(picker.endDate);
        }
    }
    const GetUserByPhoneCcOrEmailFrom = async (value) => {

        /* setUsernameFrom(FormatPhone(value)) */
        setUsernameFrom(value)

        let user = ''

        if (value[0] == '(') {
            user = value.toString().replace(/\D/g, "");
        } else {
            user = value
        }

        const data = {
            search_method: "phone",
            search_value: user.toString().toUpperCase().replace(/ /g, ""),

        }
        if (user.length > 6) {


            const response = await AxioInstance.post(`/api/get_user_by_phone_cc_email/`, {
                headers: await getAuthenticatedHeaders(),
                data

            });
            SetAliasFrom(response.data.data.alias)
        }
        /*  return response.error ? null : response.message; */
    }
    const GetUserByPhoneCcOrEmailTo = async (value) => {
        /* setUsernameTo(FormatPhone(value) */
        setUsernameTo((value)
        )
        let user = ''

        if (value[0] == '(') {
            user = value.toString().replace(/\D/g, "");
        } else {
            user = value
        }

        const data = {
            search_method: "phone",
            search_value: user.toString().toUpperCase(),

        }
        if (user.length > 6) {


            const response = await AxioInstance.post(`/api/get_user_by_phone_cc_email/`, {
                headers: await getAuthenticatedHeaders(),
                data

            });
            SetAliasTo(response.data.data.alias)
        }
        /*  return response.error ? null : response.message; */
    }

    const callApiSend = async () => {
        setIsLoading(true)
        if (latitude == null || longitude == null) {
            setShowBanner(true)
            setErrorBanner(true)
            setMsg(`Para realizar esta acción, debes aceptar los permisos de ubicación del navegador. `)
            return;
        }
        const data = {
            username_from: usernameFrom.toString().replace(/\s/g, ''),
            username_to: usernameTo.toString().replace(/\s/g, ''),
            amount: montoEnviar,
            description: description,
            latitude: latitude,
            longitude: longitude,
            currency_code: filterCurrency
        }
        AxioInstance.post(`/api/create_send_for_user/`, { data, headers: await getAuthenticatedHeaders(), })
            .then(response => {

                if (response.data.status === 200) {
                    if (response.data.statusText === "OK") {

                        setUsernameFrom("")
                        setmontoEnviar("")
                        setDescripcion("")
                        setUsernameTo("")
                        setNumber(0)
                        SetAliasTo("")

                        setDataModal(data)
                        setModal(true)

                    }
                    else {
                        setShowBanner(true)
                        setErrorBanner(true)
                        setMsg(`Ups ocurrio un error. `)
                        setUsernameFrom("")
                        setmontoEnviar("")
                        setDescripcion("")
                        setUsernameTo("")
                        setNumber(0)
                    }


                    setIsLoading(false);
                } else if (response.data.status === 400) {
                    setShowBanner(true)
                    setErrorBanner(true)
                    setMsg(response.data.data?.message)
                    setUsernameFrom("")
                    setmontoEnviar("")
                    setDescripcion("")
                    setUsernameTo("")
                    setNumber(0)

                }

            }).catch(error => setIsLoading(false), setError(error));
        setTimeout(() => {
            setIsLoading(false);
            buttonClickRef.current = false;
            // Aquí puedes hacer algo más después de recibir la respuesta de la API.
        }, 2000);

    }

    const handleChangeWithDrawAmount = (amountValue) => {


        let amount = parseInt(
            amountValue.toString().replace(/\D/g, "")
        );
        setmontoEnviar(amount)
        let withdrawAmount = Numeral(amount).format("0,0[.]00").replace(/,/g, ".");
        setNumber(withdrawAmount);
    };
    const isButtonDisabled = isLoading || usernameFrom === "" || usernameTo === "" || montoEnviar <= 0;
    useEffect(() => {
        if (descripcionValue!=='') {
            setDescripcion(descripcionValue)
        }else{
            setDescripcion('')

        }
    }, [descripcionValue])
    

    return canViewReport ? (
        <>
            {Modal && <ModalDescripcionDebitar data={dataModal} setModal={setModal} usernameFrom={aliasFrom} setUsernameFrom={SetAliasFrom} />}
            {showBanner && <Banner
                title={'Proceso realizado'}
                description={msg}
                setShowBanner={setShowBanner}
                error={errorBanner}
            />}
            <div className="flex md:flex-row flex-wrap p-4 bg-white sm:w-2/5" >


                <div className="w-full md:w-full bg-white p-4 text-center flex flex-col">
                    <input
                        className="appearance-none mb-2 block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        onChange={(e) => GetUserByPhoneCcOrEmailFrom((e.target.value))}
                        value={usernameFrom}
                        type="tel"
                        keyboard="numeral"
                        placeholder="De:"
                        pattern="[0-9]*"
                    ></input>

                    <a target='_blank' href={`${DEV}admin/api/profile/?q=${usernameFrom.replace(/\D/g, "")}`} className="text-xs ml-2 text-left">{aliasFrom}</a>

                    <input
                        className="appearance-none mb-2 block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        onChange={(e) => GetUserByPhoneCcOrEmailTo((e.target.value))}
                        value={usernameTo}
                        type="tel"
                        keyboard="numeral"
                        placeholder="Para:"
                        pattern="[0-9]*"
                    ></input>
                    <a target='_blank' href={`${DEV}admin/api/profile/?q=${usernameTo.replace(/\D/g, "")}`} className="text-xs ml-2 text-left">{aliasTo}</a>
                    <input
                        className="appearance-none mb-2 block w-full bg-gray-200 text-gray-700 border border-gray-200 py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        value={"$ " + number}
                        onChange={(e) => handleChangeWithDrawAmount(e.target.value)}
                        type="tel"
                        keyboard="numeral"
                        placeholder="Monto:"

                    ></input>
                     <select
                        className="appearance-none mb-2 block w-full bg-gray-200 text-gray-700 border border-gray-200 py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        value={descripcionValue}
                        onChange={(e) => setDescripcionValue(e.target.value)}

                    >
                        <option key={'others'} value={''}>Otro</option>

                        <option key={'BBVA'} value={'Chequera BBVA'}>Chequera BBVA</option>
                        <option key={'Davivienda'} value={'Chequera Davivienda'}>Chequera Davivienda</option>

                    </select>
                    {
                        !descripcionValue&&
                    <input
                    className="appearance-none mb-2 block w-full bg-gray-200 text-gray-700 border border-gray-200 py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    onChange={(e) => setDescripcion(e.target.value)}
                    
                    value={description}
                    type="text"
                    placeholder="Descripción (opcional)"
                    
                    
                    ></input>
                }
                    <select
                        className="appearance-none mb-2 block w-full bg-gray-200 text-gray-700 border border-gray-200 py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        value={filterCurrency}
                        onChange={(e) => setFilterCurrency(e.target.value)}

                    >
                        <option key={'COP'} value={'COP'}>COP</option>
                        <option key={'USD'} value={'USD'}>USD</option>

                    </select>

                    {usernameFrom !== "" && usernameTo !== "" && montoEnviar > 0 && <div className="font-bold text-xl mb-2 text-black w-full">
                        <button className={(usernameFrom !== "" && usernameTo !== "" && montoEnviar > 0) ? "bg-green-600 w-full text-white font-bold py-2 px-4 rounded-full text-sm" : "bg-gray-500 w-full text-white font-bold py-2 px-4 rounded-full text-sm"} onClick={() => { isLoading ? console.log(null) : callApiSend() }} disabled={isLoading}>{(isLoading) ? 'Enviando...' : `Enviar ${number !== 0 ? `$${number}` : ""} ${filterCurrency}`}</button>
                    </div>}

                    {(usernameFrom == "" && usernameTo == "" && montoEnviar == 0) && <div className="font-bold text-xl mb-2 text-black w-full">
                        <button className={"bg-gray-500 w-full text-white font-bold py-2 px-4 rounded-full text-sm"} onClick={() => { }} disabled={true}>{(isLoading) ? 'Enviando...' : `Enviar ${number !== 0 ? `$${number}` : ""} ${filterCurrency}`}</button>
                    </div>}
                </div>
                <div className="w-full md:w-2/6 bg-white p-4 text-center">
                    <div className="max-w-sm rounded overflow-hidden">
                        <div className="px-2 py-4">

                        </div>
                    </div>
                </div>
            </div>

        </>
    ) : (
        <NoView />
    )

}

export default Debitar;