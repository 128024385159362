import React, { useState, useContext, useEffect } from 'react';
import { FunctionContext } from '../../context/FuntionsContext';
import { AuthContext } from '../../context/AuthContext';
import axios from 'axios';
import { FormatAmount, isEmpty, token, TitleCase, formatCurrency } from "../common/functions";
import CircularProgress from '@material-ui/core/CircularProgress';
import Numeral from "numeral";
import AxioInstance from '../../axios/AxiosConfig';

function Tarifas() {
    const { setGuardarTarifas, GuardarTarifasClick, userTarifas, buscar, setGuardarTarifasClick } = useContext(FunctionContext);
    const { logOut, isSuperUser, montoBolsaEfecty, montoBolsaBaloto, getAuthenticatedHeaders, pendingRecargas, setPendingRecargas, getAccessToken } = useContext(AuthContext);

    const [data, setData] = useState([]);
    const [final, setFinal] = useState([]);
    const [alias, setAlias] = useState('');

    const [loading, setLoading] = useState(false);


    useEffect(() => {
        if (buscar) {
            setGuardarTarifasClick(false)

            setGuardarTarifas(false)
            setFinal([])
            setData([])

            fetchCobrus();
            fetchTarifasCopia()
        }
    }, [buscar]);
    useEffect(() => {
        if (GuardarTarifasClick == true) {

            fetchTarifasUpdates();
        }

    }, [GuardarTarifasClick]);

    const fetchTarifasUpdates = async () => {


        const response = await AxioInstance.post(`/profile/update_fees/`, {
            headers: await getAuthenticatedHeaders(),
            username: userTarifas,
            fees: data
        });


        if (response.data.status === 200) {
            setFinal([])
            setData([])
            fetchCobrus();
            setGuardarTarifas(false)
            setGuardarTarifasClick(false)
            fetchTarifasCopia()

        } else if (response.data.status === 400) {

        }


    };
    const GetUserByPhoneCcOrEmail = async (value) => {

        let user = ''

        if (value[0] == '(') {
            user = value.toString().replace(/\D/g, "");
        } else {
            user = value
        }

        const data = {
            search_method: "phone",
            search_value: user.toString().toLowerCase().replace(/ /g, ""),

        }
        if (user.length > 6) {


            const response = await AxioInstance.post(`/api/get_user_by_phone_cc_email/`, {
                headers: await getAuthenticatedHeaders(),
                data

            });
            setAlias(response.data.data.first_name + " " + response.data.data.last_name)
        }
        /*  return response.error ? null : response.message; */
    }
    const fetchTarifasCopia = async (page) => {
        setLoading(true);

        const response = await AxioInstance.post(`/api/user/tarifas`, {
            headers: await getAuthenticatedHeaders(),
            username: userTarifas
        });

        if (response.data.status === 200) {
            GetUserByPhoneCcOrEmail(userTarifas)
            const withdraws = response.data.data.fees;

            setFinal(withdraws);
            setLoading(false);
        } else if (response.data.status === 400) {

        }

        setLoading(false);
    };
    const fetchCobrus = async (page) => {
        setLoading(true);

        const response = await AxioInstance.post(`/api/user/tarifas`, {
            headers: await getAuthenticatedHeaders(),
            username: userTarifas
        });

        if (response.data.status === 200) {
            const withdraws = response.data.data.fees;
            console.log("🚀 ~ fetchCobrus ~ withdraws:", withdraws)

            setData(withdraws);
            setLoading(false);
        } else if (response.data.status === 400) {

        }

        setLoading(false);
    };

    useEffect(() => {

        if (data.length != 0 && data.length != 0) {


            if ((JSON.stringify(data) == JSON.stringify(final))) {
                setGuardarTarifas(false);
            } else {
                setGuardarTarifas(true);
            }
        } else {
            setGuardarTarifas(false);
        }
    }, [data,final]);

    const handleCellValueChange = (rowIndex, columnId, value) => {
        const updatedData = [...data];
        if (value === "") {
            updatedData[rowIndex][columnId] = NaN;
        } else {
            updatedData[rowIndex][columnId] = parseFloat(value);
        }
        setData(updatedData);
    };

    // Resto del código...
    function capitalize(word) {
        const lower = word.toLowerCase();
        return word.charAt(0).toUpperCase() + lower.slice(1);
    }
    const Circular = () => (
        <div style={{ padding: '24px' }}>
            {
                loading &&
                <CircularProgress color="red" size={90} />
            }
        </div>
    );
    const FormatAmountPoint = (amount) => {
        if (!amount) return "$ 0";
        let digits = amount.toString().replaceAll(/\s/g, '');

        // Usa el formato '0,0.00' para mostrar dos decimales
        return "$ " + Numeral(parseFloat(digits)).format('0,0.00').replace(/,/g, '.');
    }
    return (
        <>
            {loading && <div className='flex w-full items-center justify-center'><Circular /></div>}
            <div style={{
                width: "100%", background: "#ffffff",
                paddingLeft: '3rem',
                display: "flex",
                paddingRight: '3rem',
                textAlign: 'left',
                height: 56,
                alignItems: "center"
            }}>
                <p>{alias}</p>
            </div>
            {data.length !== 0 && loading !== true &&
                <table style={{ width: "100%", }} >
                    {<thead style={{ height: 56, borderBottom: "  1px solid rgba(0, 0, 0, 0.12)" }}>

                        <tr style={{ backgroundColor: "#ffffff", fontSize: 13 }}>
                            <th style={{ paddingLeft: '3rem', paddingRight: '3rem', textAlign: "left" }}>MEDIO</th>
                            <th style={{ paddingLeft: '3rem', paddingRight: '3rem', textAlign: "left" }}>PORCENTUAL</th>
                            <th style={{ paddingLeft: '3rem', paddingRight: '3rem', textAlign: "left" }}>FIJA</th>
                            <th style={{ paddingLeft: '3rem', paddingRight: '3rem', textAlign: "left" }}>MINIMA</th>
                            <th style={{ paddingLeft: '3rem', paddingRight: '3rem', textAlign: "left" }}>IMPUESTO</th>
                        </tr>
                    </thead>}

                    <tbody >
                        {data.map((row, rowIndex) => (
                            <tr key={rowIndex} style={{ backgroundColor: "#ffffff", height: 56, borderBottom: "  1px solid rgba(0, 0, 0, 0.12)" }}>
                                <td style={{ paddingLeft: '3rem', paddingRight: '3rem', textAlign: "left" }}>
                                    <p className="ml-3">{(row.method == "corresponsal_bancolombia" ? 'Corresponsal Bancolombia' :
                                        row.method == 'super_giros' ? 'Super giros' :
                                            row.method == 'CUSD' ? 'CUSD' :
                                                row.method == 'BTC' ? 'BTC' :
                                                    row.method == 'pse' ? 'PSE' :
                                                        row.method == 'bancolombia_qr' ? 'QR Bancolombia' :
                                                        row.method == 'credit_card_international' ? 'Tarjeta de crédito internacional' :
                                                        row.method == 'BCH' ? 'BCH' :
                                                        row.method == 'DASH' ? 'DASH' :
                                                        row.method == 'DAI' ? 'DAI' :

                                                            row.method == 'credit_card' && row.franchise !== '' ? capitalize(row.franchise) :
                                                                row.method == 'bancolombiapay' ? 'Bancolombia Pay' :
                                                                    row.method == 'bancolombia_transfer' ? 'Botón Bancolombia' :
                                                                        row.method == 'cash' ? 'Retiro en Efecty' :
                                                                            row.method == 'withdraw' ? 'Retiro en bancos' :
                                                                                row.method == 'credit_card' && row.franchise === '' ? 'Tarjeta de crédito' :
                                                                                    row.method == 'CUSD' ? 'CUSD' : capitalize(row.method))}</p></td>
                                <td style={{ paddingLeft: '3rem', paddingRight: '3rem', textAlign: "left" }}>


                                    <input
                                        type="number"
                                        style={{ backgroundColor: "#f6f5f6", width: "100%", paddingRight: "0.5rem", paddingLeft: "0.5rem", borderRadius: '0.25rem', borderWidth: 1 }}
                                        value={row.fee_variable}
                                        suffix="%"
                                        inputMode="numeric"
                                        placeholder={`${(row.method == 'cash' ? row.fee_variable * 100 : row.fee_variable)}%`}
                                        onChange={e => handleCellValueChange(rowIndex, 'fee_variable', e.target.value)}
                                    />

                                </td>
                                <td style={{ paddingLeft: '2rem', paddingRight: '2rem', textAlign: "left" }}>

                                    <input
                                        type="number"
                                        style={{ backgroundColor: "#f6f5f6", width: "100%", paddingRight: "0.5rem", paddingLeft: "0.5rem", borderRadius: '0.25rem', borderWidth: 1 }}
                                        value={row.fee_flat}
                                        placeholder={FormatAmountPoint(row.fee_flat)}
                                        onChange={e => handleCellValueChange(rowIndex, 'fee_flat', e.target.value)}
                                    />
                                </td>
                                <td style={{ paddingLeft: '3rem', paddingRight: '3rem', textAlign: "left" }}>
                                    <input
                                        style={{ backgroundColor: "#f6f5f6", width: "100%", paddingRight: "0.5rem", paddingLeft: "0.5rem", borderRadius: '0.25rem', borderWidth: 1 }}
                                        type="number"
                                        value={row.min_fee}
                                        placeholder={FormatAmount(row.min_fee)}
                                        onChange={e => handleCellValueChange(rowIndex, 'min_fee', e.target.value)}
                                    />
                                </td>
                                <td style={{ paddingLeft: '3rem', paddingRight: '3rem', textAlign: "left" }}>
                                    <input
                                        style={{ backgroundColor: "#f6f5f6", width: "100%", paddingRight: "0.5rem", paddingLeft: "0.5rem", borderRadius: '0.25rem', borderWidth: 1 }}
                                        type="number"
                                        value={row.iva}
                                        placeholder={`IVA (${row.iva}%)`}
                                        onChange={e => handleCellValueChange(rowIndex, 'iva', e.target.value)}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </ table>}
        </>
    );
}

export default Tarifas;