import React, { useState, useEffect, useContext, useCallback } from "react";
import { BsCheckCircle } from "react-icons/bs";
import { Button } from "@material-ui/core";
import numeral from "numeral";
import Dropzone from 'react-dropzone';
import { FaFileExcel } from "react-icons/fa";
import { /* token, */ isEmpty, FormatDocument } from '../common/functions';
import axios from 'axios';
import { AuthContext } from '../../context/AuthContext';
import File from '../../assets/files/Envios_Lotes.xlsm';
import Select, { StylesConfig } from 'react-select';
import { defaultTheme } from 'react-select';
import { FaSearch } from "react-icons/fa"
import CircularProgress from "@material-ui/core/CircularProgress";
import AxioInstance from '../../axios/AxiosConfig';


export default function ModalHacerRetiro({ setShowModalAgg, bankAccounts, setBankAccounts, setBank, Bank, isLoading, setIsLoading, BankAccountsList = [], bankAccount, setBankAccount, optionValueSelectBanks, BankType, setBankType, BankAcount, setBankAcount, loading, consultar, number, FORMAT, setBannerContent, setShowBanner, setBannerError, setShowModalRetirar }) {
    const [page, setPage] = useState(2);

    const [Data, setData] = useState([])
    const [filter, setFilter] = useState('')

    const [TypeDocument, setTypeDocument] = useState('')
    const [NumDocument, setNumDocument] = useState('')
    const [banco, setBanco] = useState('')
    const [BuscarCuentas, setBuscarCuentas] = useState('')
    const [Buscar, setBuscar] = useState(false)
    const [lote, setLote] = useState(false)
    const [nombre, setNombre] = useState('')
    const [TypeCuenta, setTypeCuenta] = useState('')
    const [NumCuenta, setNumCuenta] = useState('')
    const [error, setError] = useState('')
    const [hasMore, setHasMore] = useState(true);
    const [Loading, setLoading] = useState(false);

    useEffect(() => {
        if (Buscar) {
            BuscarCuenta()
        }

    }, [Buscar])

    const { getAuthenticatedHeaders, getAccessToken, permissions } = useContext(AuthContext)
    const options = [
        { value: 'chocolate', label: 'Chocolate' },
        { value: 'strawberry', label: 'Strawberry' },
        { value: 'vanilla', label: 'Vanilla' }
    ]
    useEffect(() => {
        document.addEventListener("keydown", escFunction);
    }, []);
    const handleScroll = (event) => {
        const target = event.target;
        if (target.scrollHeight - target.scrollTop === target.clientHeight && !isLoading && hasMore) {
            loadOptions();
        }
    };
    const loadOptions = async () => {

        // Simula una llamada a una API para cargar más opciones
        const newOptions = await AxioInstance.post(`/api/getListBankUser`, {
            headers: await getAuthenticatedHeaders(),
            page: page
        });
        setIsLoading(false);

        if (newOptions.data.status === 404 && newOptions.data.data.detail === "Página inválida.") {
            setBankAccounts((prevOptions) => [...prevOptions, {
                account_holder_document: "0",
                account_holder_document_type: 0,
                account_holder_name: "",
                account_number: "",
                account_type: 0,
                bank_name: 0,
                pk: 0,
                state: ""
            }]);
            setHasMore(false);
            return;
        }
        if (newOptions.data.status >= 400) {
            setHasMore(false);
            return;
        }
        if (newOptions.data.data.count === 0) {
            setHasMore(false);
            return;
        }
        var init = bankAccounts
        var final =

            setBankAccounts((prevOptions) => [...prevOptions, ...newOptions.data.data.results]);

        setPage((prevPage) => prevPage + 1);
    };





    const idBank = (id) => {
        let banco = ''
        if (id == 0) {
            banco = 'Bancolombia'
        } else if (id == 1) {
            banco = 'Banco Bogota'
        } else if (id == 2) {
            banco = 'Davivienda'
        } else if (id == 3) {
            banco = 'BBVA'
        } else if (id == 4) {
            banco = 'Banco Occidente'
        } else if (id == 5) {
            banco = 'Colpatria'
        } else if (id == 6) {
            banco = 'Banco Popular'
        } else if (id == 7) {
            banco = 'Helm Bank'
        } else if (id == 8) {
            banco = 'Banco Agrario'
        } else if (id == 9) {
            banco = 'Banco BCSC'
        } else if (id == 10) {
            banco = 'Banco CorpBanca'
        } else if (id == 11) {
            banco = 'Banco Citibank'
        } else if (id == 12) {
            banco = 'Banco AV Villas'
        } else if (id == 13) {
            banco = 'Banco ProCredit'
        } else if (id == 14) {
            banco = 'Banco Pichincha'
        } else if (id == 15) {
            banco = 'Banco Bancoomeva'
        } else if (id == 16) {
            banco = 'Banco Santander'
        } else if (id == 17) {
            banco = 'Banco Falabella'
        } else if (id == 18) {
            banco = 'Nequi'
        } else if (id == 19) {
            banco = 'Banco DaviPlata'
        } else if (id == 20) {
            banco = 'Banco Finandina'
        } else if (id == 21) {
            banco = 'Banco Itaú'
        } else if (id == 22) {
            banco = 'Banco Itaú CorpBanca'
        } else if (id == 23) {
            banco = 'Lulo Bank'
        } else if (id == 25) {
            banco = "Ualá"
        } else if (id == 26) {
            banco = "Banco Cooperativo Coopcentral"
        } else if (id == 27) {
            banco = "Rappi pay"
        } else {
            banco = ""
        }
        return banco
    }

    const BuscarCuenta = async () => {
        setIsLoading(true)
        try {
            const response = await AxioInstance.post(`/api/getListBankUser/search`, {
                headers: await getAuthenticatedHeaders(),
                buscar: filter
            });
            if (response.data.status === 200) {
                let data = response.data.data;
                setBankAccounts(data)
            }
            setIsLoading(false)
        } catch (error) {
        }
    }
    const BuscarCuentaAll = async () => {
        setIsLoading(true)
        try {
            const response = await AxioInstance.post(`/api/getListBankUser/search`, {
                headers: await getAuthenticatedHeaders(),
                buscar: ""
            });
            if (response.data.status === 200) {
                let data = response.data.data;
                setBankAccounts(data)
            }
            setIsLoading(false)
        } catch (error) {
        }
    }
    const formatOptionLabel = ({ account_holder_name, bank_name, account_type, account_number }) => (
        <div style={{ display: "flex" }}>
            <div style={{ marginLeft: "10px", }}>

            </div>
        </div>
    );
    const guardarBanco = (e) => {
        setBank(e)
        setShowModalAgg(false)
        setFilter("")
        BuscarCuentaAll()
    }
    const escFunction = useCallback((event) => {
        if (event.keyCode === 27) {
            setShowModalAgg(false)

        }

    })
    const imgBank = (item) => {
        let image = ''

        if ((item) === ('Bancolombia') || idBank(item) === ('Bancolombia')) image = require("../../assets/payment_methods/bancolombia.png");
        if ((item) === ('Banco Bogota') || idBank(item) === ('Banco Bogota')) image = require("../../assets/payment_methods/bancobogota.jpg");
        if ((item) === ('Davivienda') || idBank(item) === ('Davivienda')) image = require("../../assets/images/bancodavivienda.png");
        if ((item) === ('BBVA') || idBank(item) === ('BBVA')) image = require("../../assets/payment_methods/bbva.png");
        if ((item) === ('Banco Occidente') || idBank(item) === ('Banco Occidente')) image = require("../../assets/payment_methods/bancooccidente.png");
        if ((item) === ('Colpatria') || idBank(item) === ('Colpatria')) image = require("../../assets/payment_methods/colpatria.png");
        if ((item) === ('Banco Popular') || idBank(item) === ('Banco Popular')) image = require("../../assets/payment_methods/bancopopular.png");
        if ((item) === ('Helm Bank') || idBank(item) === ('Helm Bank')) image = require("../../assets/payment_methods/bancohelm.png");
        if ((item) === ('Banco Agrario') || idBank(item) === ('Banco Agrario')) image = require("../../assets/payment_methods/bancoagrario.png");
        if ((item) === ('Banco BCSC') || idBank(item) === ('Banco BCSC')) image = require("../../assets/payment_methods/bancocajasocial.png");
        if ((item) === ('Banco CorpBanca') || idBank(item) === ('Banco CorpBanca')) image = require("../../assets/payment_methods/corpbanca.png");
        if ((item) === ('Banco Citibank') || idBank(item) === ('Banco Citibank')) image = require("../../assets/payment_methods/citibank.jpg");
        if ((item) === ('Avvillas') || idBank(item) === ('Banco AV Villas')) image = require("../../assets/payment_methods/bancoavvillas.png");
        if ((item) === ('Banco ProCredit') || idBank(item) === ('Banco ProCredit')) image = require("../../assets/payment_methods/bancoprocredit.png");
        if ((item) === ('Banco Pichincha') || idBank(item) === ('Banco Pichincha')) image = require("../../assets/payment_methods/bancopichincha.png");
        if ((item) === ('Banco Bancoomeva') || idBank(item) === ('Banco Bancoomeva')) image = require("../../assets/payment_methods/bancoomeva.jpg");
        if ((item) === ('Banco Santander') || idBank(item) === ('Banco Santander')) image = require("../../assets/payment_methods/bancosantander.png");
        if ((item) === ('Banco Falabella') || idBank(item) === ('Banco Falabella')) image = require("../../assets/payment_methods/bancofalabella.png");
        if ((item) === ('Nequi') || idBank(item) === ('Nequi')) image = require("../../assets/payment_methods/nequi.png");
        if ((item) === ('Banco DaviPlata') || idBank(item) === ('Banco DaviPlata')) image = require("../../assets/payment_methods/bancodaviplata.png");
        if ((item) === ('Banco Finandina') || idBank(item) === ('Banco Finandina')) image = require("../../assets/payment_methods/bancofinandina.png");
        if ((item) === ('Banco Itaú') || idBank(item) === ('Banco Itaú')) image = require("../../assets/payment_methods/itau.png");
        if ((item) === ('Banco Itaú CorpBanca') || idBank(item) === ('Banco Itaú CorpBanca')) image = require("../../assets/payment_methods/corpbanca.png");
        if ((item) === ('deposito') || idBank(item) === ('deposito')) image = require("../../assets/images/pagos-gde.png");
        if ((item) === ('Ualá') || idBank(item) === ('Ualá')) image = require("../../assets/images/uala.png");
        if ((item) === ('Rappipay') || idBank(item) === ('Rappi pay')) image = require("../../assets/payment_methods/rappipay-icon.png");


        if ((item) === ('Banco Cooperativo Coopcentral') || idBank(item) === ('Banco Cooperativo Coopcentral')) image = require("../../assets/images/coopcentral.png");




        return (image)
    }

    const CustomOption = ({ value, label, innerProps, isDisabled, }) =>


    (
        <div {...innerProps} className="flex flex-row items-center hover-bac " style={{
            paddingLeft: 10, paddingRight: 10, marginBottom: 10, marginTop: 10,
            background: Bank.pk === value.pk ? '#37ce27' : null
        }}>
            <div className="flex items-center">
                <div
                    title={value.state}
                    style={{
                        backgroundColor:
                            value.state === "Creada" ? '#ecc94b' :
                                value.state === "inscrita" ? "#48bb78" :
                                    value.state === "En proceso de inscripcion" ? "#fa9704" :
                                        "", width: 10, height: 10, borderRadius: 50
                    }}></div>
                <div><img style={{ marginLeft: 8, marginRight: 8, width: 35, height: 35, borderRadius: 50 }} src={imgBank(value.bank_name)} /></div>
            </div>
            <div>
                <p>{value.account_holder_name} </p>
                <p>
                    {value.account_type == 0 ? "Ahorro" : "Corriente"} - {value.account_number}
                </p>
            </div>
        </div>

    );
    const LoadingIndicator = () => (
        <div className="">
            <div className="w-full">
                <div style={{}}>
                    <div className="h-full w-full flex items-center justify-center">
                        <CircularProgress size={25} />
                    </div>
                </div>
            </div>
        </div>
    );
    const openData = () => {
        setShowModalAgg(false)
        setShowModalRetirar(true)
    }

    return (
        <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none" >
                <div className="" >

                    <div className="flex-col  flex bg-blue-400 h-1/3x md:w-full  rounded border-solid shadow-lg  w-full" style={{ background: "#141319" }}>
                        <button
                            className="p-1 ml-auto bg-transparent border-0 text-white opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                            onClick={() => setShowModalAgg(false)}
                        >
                            <span className="bg-transparent text-white opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                ×
                            </span>
                        </button>
                        <div className="w-full " style={{ minWidth: 600, }}>



                            <div className=" mx-3 mb-6 mt-6  items-center justify-center flex-col">

                                <div className="flex flex-row mb-6" style={{ height: 45, }} >
                                    <input className='backgroundColursLayouts colursPlasholder colursMainWhite border rounded pl-2 pr-10 w-full  text-base focus:outline-none' value={filter} onChange={(e) => setFilter(e.target.value)} placeholder={'Buscar por numero de cuenta, documento o nombre'}
                                        onKeyPress={(event) => {
                                            if (event.key === 'Enter') {
                                                setBuscar(!Buscar)
                                            }
                                        }
                                        }
                                    />
                                    <button className='focus:outline-none' style={{ marginLeft: -30, }} onClick={() => setBuscar(!Buscar)}> <FaSearch color={'#a0aec0'} size={16} /></button>
                                </div>
                                {!isLoading &&
                                    <div onScroll={handleScroll} style={{ height: 350, overflow: "scroll" }}>

                                        {bankAccounts.map((item, i) => item.pk !== 0 ? (
                                            <div onClick={() => guardarBanco(item)} className="flex flex-row items-center hover-bac text-white cursor-pointer rounded backgroundLayout " style={{
                                                paddingLeft: 10, paddingRight: 10, marginBottom: 10, marginTop: 10,
                                                background: Bank.pk === item.pk ? 'white' : null,
                                                color: Bank.pk === item.pk ? 'black' : null
                                            }}>
                                                <div className="flex items-center">
                                                    <div
                                                        title={item.state}
                                                        style={{
                                                            backgroundColor:
                                                                item.state === "Creada" ? '#ecc94b' :
                                                                    item.state === "inscrita" ? "#48bb78" :
                                                                        item.state === "En proceso de inscripcion" ? "#fa9704" :
                                                                            "", width: 10, height: 10, borderRadius: 50
                                                        }}></div>
                                                    <div><img style={{ marginLeft: 8, marginRight: 8, width: 35, height: 35, borderRadius: 50 }} src={imgBank(item.bank_name)} /></div>
                                                </div>
                                                <div>
                                                    <p>{item.account_holder_name} </p>
                                                    <p>
                                                        {item.account_type == 0 ? "Ahorro" : "Corriente"} - {item.account_number}
                                                    </p>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="flex flex-row items-center hover-bac " style={{
                                                textAlign: "center", marginBottom: 10, marginTop: 10, height: 48, alignItems: "center", justifyContent: "center", color: "#a4b2c4"
                                            }}>
                                                No hay mas cuentas
                                            </div>
                                        ))}



                                        {/*   <Select


                                            components={{
                                                Option: CustomOption,
                                                LoadingIndicator,
                                                IndicatorSeparator: null, // Opcional: para ocultar el separador del indicador
                                            }}
                                            styles={{
                                                control: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    "&:hover": {
                                                        borderColor: '#e2e8f0',
                                                        background: 'white',

                                                    },
                                                    background: 'rgb(237, 242, 247)',
                                                    outline: 'none',
                                                    boxShadow: 'none',
                                                    borderColor: 'none'
                                                }),

                                            }}

                                            isLoading={Loading}
                                            value={Bank}
                                            onChange={e => guardarBanco(e)}
                                            placeholder='Seleccionar cuenta o buscar'
                                            getOptionValue={(option) => option}
                                            getOptionLabel={(option) =>
                                                `${(option.account_holder_name)} - ${idBank(option.bank_name)} - ${option.account_type == 0 ? "Ahorro" : "Corriente"} - ${option.account_number} - ${typeDocument(option.account_holder_document_type)} ${(option.account_holder_document)} `
                                            }
                                            options={bankAccounts}

                                        /> */}
                                        {/* {Bank.map((item,) => (
                                            <div>
                                                <CustomOption />
                                            </div>
                                        )
                                        )} */}
                                    </div>}
                                {isLoading &&
                                    <div className="flex ">
                                        <div className="w-full">
                                            <div style={{}}>
                                                <div className="h-full w-full flex items-center justify-center">
                                                    <CircularProgress size={75} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                }
                                <div className="w-full flex items-center justify-center">
                                    <button
                                        autoFocus={false}
                                        type="reset"
                                        className="w-1/4 mt-4 border-transparent links text-sm border-4 text-green-600 py-2 rounded-full button-outline-off "
                                        onClick={() => openData()}
                                    > Agregar cuenta
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >

            <div className=" fixed inset-0 z-40 " style={{
                background: 'rgba(47, 46, 44, 0.5)',
                backdropFilter: 'blur(10px)',
            }}></div>
        </>
    );
}