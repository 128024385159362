import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import axios from 'axios'


import Dashboard from "./containers/layouts/Dashboard";
import Login from './components/Login';
import PrivateRoute from './components/PrivateRoute';
import PublicRoute from './components/PublicRoute';
import FunctionContext from "./context/FuntionsContext";
import AuthContext from "./context/AuthContext";

export default function App() {
  return (
    <AuthContext.Provider>
      <FunctionContext.Provider>
        <div className="font-body text-trixel-color backgroundColurs">
          <Router>
            <Switch>
              <PublicRoute component={Login} path="/login" exact />
              <PrivateRoute component={Dashboard} path="/:path?" exact />
            </Switch>
          </Router>
        </div>
      </FunctionContext.Provider>
    </AuthContext.Provider>
  );
}