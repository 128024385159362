import React, { useState, useEffect, useContext } from "react";
import { BsCheckCircle } from "react-icons/bs";
import { Button } from "@material-ui/core";
import numeral from "numeral";
import { /* getAuthenticatedHeaders, */ FormatAmount, FormatPhoneNumber, FormatDocument, maskCardNumber } from '../common/functions';
import moment from 'moment';
import { AuthContext } from "../../context/AuthContext";

export default function ModalCobrar(props) {

    const [copiar, setCopiar] = useState(false)
    const [browserName, setBrowserName] = useState("");
    const [showModal, setShowModal] = useState(props.open);
    const [sistemaOP, setSistemaOP] = useState("");
    const [imgens, setImagen] = useState("");
    const [color, setColor] = useState("");
    const [colorText, setColorText] = useState("");
    const DEV = localStorage.getItem('DEV');
    const { isSuperUser } = useContext(AuthContext)

    let COBRU_ME = DEV === 'https://prod.cobru.co/' ? 'https://cobru.me/' : "https://dev.cobru.co/"


    useEffect(() => {
        setColor(
            props.data.state === 0 || props.data.state === 1
                ? "border-yellow-500"
                : props.data.state === 2
                    ? "border-green-500"
                    : props.data.state === 3
                        ? "border-green-500"
                        : props.data.state === 4
                            ? "border-red-600"
                            : props.data.state === 5 || props.data.state === 6
                                ? "border-red-600" : null
        )
        setColorText(props.data.state === 0 || props.data.state === 1
            ? "text-yellow-500"
            : props.data.state === 2
                ? "text-green-500"
                : props.data.state === 3
                    ? "text-green-500"
                    : props.data.state === 4
                        ? "text-red-600"
                        : props.data.state === 5 || props.data.state === 6
                            ? "text-red-600" : null)

    }, [])



    useEffect(() => {
        if (copiar == true) {


            const timer = setTimeout(() => {
                setCopiar(false)
            }, 2500);
        }
    }, [copiar]);
    const title = `REF-${props.data.pk}`;
    /* const userIp = /* props.info.userIp */
    const today = new Date();
    var ampm = today.getHours() >= 12 ? 'pm' : 'am';
    /*     const comision_retiro = props.info.fee;
        const comision_iva_retiro = props.info.fee_iva;
        const gmf = props.info.gmf;
        const payed_amount = props.info.payed_amount;
        const amount = props.info.amount; */

    function formatDate(x) {
        let currentTimestamp = x - 18000000

        /* let date = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' }).format(currentTimestamp) */
        let date = moment(x).format('DD/MM/YYYY HH:mm A')

        return date;
    }
    /* useEffect(() => {
        imgBank()
    }, []) */
    const imgBank = (item, data) => {
        let image = ''

        if ((item) === ('pse') || bankName(item) === ('pse')) image = require("../../assets/payment_methods/pse-png.png");
        if ((item) === ('credit_card') && (data.card_info?.franchise === "AMEX" || data.franchise === "AMEX")) image = require("../../assets/payment_methods/amexRounded.png");
        if ((item) === ('credit_card') && (data.card_info?.franchise === "MasterCard" || data.franchise === "MasterCard")) image = require("../../assets/payment_methods/mastercardRounded.png");
        if ((item) === ('credit_card') && (data.card_info?.franchise === "Visa" || data.franchise === "Visa")) image = require("../../assets/payment_methods/visaRounded.png");
        if ((item) === ('credit_card') && (data.card_info?.franchise === "Diners" || data.franchise === "Diners")) image = require("../../assets/payment_methods/dinersRounded.png");
        if ((item) === ('credit_card') && (data.card_info?.franchise === "Unknown" || data.franchise === "Unknown")) image = require("../../assets/payment_methods/tcRounded.png");
        if ((item) === ('credit_card') && (data.card_info?.franchise === undefined || data.franchise === undefined)) image = require("../../assets/payment_methods/tcRounded.png");
        if ((item) === ('credit_card_international') && (data.card_info?.franchise === "AMEX" || data.franchise === "AMEX")) image = require("../../assets/payment_methods/amexRounded.png");
        if ((item) === ('credit_card_international') && (data.card_info?.franchise === "MasterCard" || data.franchise === "MasterCard")) image = require("../../assets/payment_methods/mastercardRounded.png");
        if ((item) === ('credit_card_international') && (data.card_info?.franchise === "Visa" || data.franchise === "Visa")) image = require("../../assets/payment_methods/visaRounded.png");
        if ((item) === ('credit_card_international') && (data.card_info?.franchise === "Diners" || data.franchise === "Diners")) image = require("../../assets/payment_methods/dinersRounded.png");
        if ((item) === ('credit_card_international') && (data.card_info?.franchise === "Unknown" || data.franchise === "Unknown")) image = require("../../assets/payment_methods/tcRounded.png");


        if ((item) === ('bancolombia_transfer') || bankName(item) === ('pse')) image = require("../../assets/payment_methods/corresponsal-bancolombia-icon.png");
        if ((item) === ('Bancolombia') || bankName(item) === ('Bancolombia')) image = require("../../assets/payment_methods/bancolombia.png");
        if ((item) === ('bancolombiaqr') || bankName(item) === ('bancolombiaqr')) image = require("../../assets/payment_methods/corresponsal-bancolombia-icon.png");
        if ((item) === ('Davivienda') || bankName(item) === ('Davivienda')) image = require("../../assets/payment_methods/bancodavivienda.png");
        if ((item) === ('BBVA') || bankName(item) === ('BBVA')) image = require("../../assets/payment_methods/bbva.png");
        if ((item) === ('Banco Occidente') || bankName(item) === ('Banco Occidente')) image = require("../../assets/payment_methods/bancooccidente.png");
        if ((item) === ('Colpatria') || bankName(item) === ('Colpatria')) image = require("../../assets/payment_methods/colpatria.png");
        if ((item) === ('Banco Popular') || bankName(item) === ('Banco Popular')) image = require("../../assets/payment_methods/bancopopular.png");
        if ((item) === ('Helm Bank') || bankName(item) === ('Helm Bank')) image = require("../../assets/payment_methods/bancohelm.png");
        if ((item) === ('Banco Agrario') || bankName(item) === ('Banco Agrario')) image = require("../../assets/payment_methods/bancoagrario.png");
        if ((item) === ('Banco BCSC') || bankName(item) === ('Banco BCSC')) image = require("../../assets/payment_methods/bancocajasocial.png");
        if ((item) === ('Banco CorpBanca') || bankName(item) === ('Banco CorpBanca')) image = require("../../assets/payment_methods/corpbanca.png");
        if ((item) === ('Banco Citibank') || bankName(item) === ('Banco Citibank')) image = require("../../assets/payment_methods/citibank.jpg");
        if ((item) === ('Avvillas') || bankName(item) === ('Banco AV Villas')) image = require("../../assets/payment_methods/bancoavvillas.png");
        if ((item) === ('Banco ProCredit') || bankName(item) === ('Banco ProCredit')) image = require("../../assets/payment_methods/bancoprocredit.png");
        if ((item) === ('Banco Pichincha') || bankName(item) === ('Banco Pichincha')) image = require("../../assets/payment_methods/bancopichincha.png");
        if ((item) === ('corresponsal_bancolombia') || bankName(item) === ('corresponsal_bancolombia')) image = require("../../assets/payment_methods/corresponsal-bancolombia-icon.png");
        if ((item) === ('Banco Santander') || bankName(item) === ('Banco Santander')) image = require("../../assets/payment_methods/bancosantander.png");
        if ((item) === ('efecty') || bankName(item) === ('efecty')) image = require("../../assets/payment_methods/efectyRounded.png");
        if ((item) === ('NEQUI') || (item) === ('NEQUI')) image = require("../../assets/payment_methods/nequi.png");
        if ((item) === ('dale') || bankName(item) === ('dale')) image = require("../../assets/payment_methods/dale-icon.png");
        if ((item) === ('BTC') || bankName(item) === ('BTC')) image = require("../../assets/payment_methods/bitcoin.png");
        if ((item) === ('CUSD') || bankName(item) === ('Banco Itaú')) image = require("../../assets/payment_methods/celo-dolar.png");
        if ((item) === ('bancolombiapay') || bankName(item) === ('bancolombiapay')) image = require("../../assets/payment_methods/bc-card.png");
        if ((item) === ('Ualá') || bankName(item) === ('Ualá')) image = require("../../assets/images/uala.png");
        if ((item) === ('Rappipay') || bankName(item) === ('Rappi pay')) image = require("../../assets/payment_methods/rappiPay.png");


        if ((item) === ('deposito') || bankName(item) === ('deposito')) image = require("../../assets/images/pagos-gde.png");
        if ((item) === ('transfer') || bankName(item) === ('transfer')) image = require("../../assets/payment_methods/cobruRounded.png");

        return (image)
    }
    const bankName = (id) => {

        let banco = ''
        if (id == 0) {
            banco = 'Bancolombia'
        } else if (id == 1) {
            banco = 'Banco Bogota'
        } else if (id == 2) {
            banco = 'Davivienda'
        } else if (id == 3) {
            banco = 'BBVA'
        } else if (id == 4) {
            banco = 'Banco Occidente'
        } else if (id == 5) {
            banco = 'Colpatria'
        } else if (id == 6) {
            banco = 'Banco Popular'
        } else if (id == 7) {
            banco = 'Helm Bank'
        } else if (id == 8) {
            banco = 'Banco Agrario'
        } else if (id == 9) {
            banco = 'Banco BCSC'
        } else if (id == 10) {
            banco = 'Banco CorpBanca'
        } else if (id == 11) {
            banco = 'Banco Citibank'
        } else if (id == 12) {
            banco = 'Banco AV Villas'
        } else if (id == 13) {
            banco = 'Banco ProCredit'
        } else if (id == 14) {
            banco = 'Banco Pichincha'
        } else if (id == 15) {
            banco = 'Banco Bancoomeva'
        } else if (id == 16) {
            banco = 'Banco Santander'
        } else if (id == 17) {
            banco = 'Banco Falabella'
        } else if (id == 18) {
            banco = 'Nequi'
        } else if (id == 19) {
            banco = 'Banco DaviPlata'
        } else if (id == 20) {
            banco = 'Banco Finandina'
        } else if (id == 21) {
            banco = 'Banco Itaú'
        } else if (id == 22) {
            banco = 'Banco Itaú CorpBanca'
        } else if (id == 23) {
            banco = 'Lulo Bank'
        } else if (id == 24) {
            banco = 'Coltefinanciera'
        } else if (id == 25) {
            banco = "Ualá"
        } else if (id == 26) {
            banco = "Banco Cooperativo Coopcentral"
        } else if (id == 27) {
            banco = "Rappi pay"
        }
        return banco
    }

    const copy = (text) => {

        var id = "el-id-del-textarea";
        var existsTextarea = document.getElementById(id);

        if (!existsTextarea) {

            var textarea = document.createElement("textarea");
            textarea.id = id;
            // Coloca el textarea en el borde superior izquierdo
            textarea.style.position = 'fixed';
            textarea.style.top = 0;
            textarea.style.left = 0;
            textarea.style.textTransform = "lowercase"
            // Asegurate que las dimensiones del textarea son minimas, normalmente 1px 
            // 1em no funciona porque esto generate valores negativos en algunos exploradores
            textarea.style.width = '1px';
            textarea.style.height = '1px';

            // No se necesita el padding
            textarea.style.padding = 0;

            // Limpiar bordes
            textarea.style.border = 'none';
            textarea.style.outline = 'none';
            textarea.style.boxShadow = 'none';

            // Evitar el flasheo de la caja blanca al renderizar
            textarea.style.background = 'transparent';
            document.querySelector("body").appendChild(textarea);

            existsTextarea = document.getElementById(id);
        } else {

        }

        existsTextarea.value = text;
        existsTextarea.select();

        try {
            var status = document.execCommand('copy');
            if (!status) {
                console.error("No se pudo copiar el texto");
            } else {

                setCopiar(true)
            }
        } catch (err) {

        }

    }
    const typeDocument = (id) => {
        let type = ''
        if (id == 0) {
            type = 'CC'
        } else if (id == 1) {
            type = 'CE'
        } else if (id == 2) {
            type = 'TI'
        } else if (id == 3) {
            type = 'NIT'
        } else if (id == 4) {
            type = 'PSP'
        }
        return type
    }





    return (
        <>
            {showModal ? (
                <>
                    <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">


                        <div className="relative w-pop-up my-6 mx-auto max-w-md">

                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full backgroundColurs outline-none focus:outline-none">
                                <button
                                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                    onClick={() => props.modal(false)}
                                >
                                    <span className="bg-transparent text-white opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                        ×
                                    </span>
                                </button>
                                <div className="relative px-6 pb-1 flex-auto">
                                    {copiar && <div className="copiado-modal">Copiado

                                    </div>}

                                    <h3 className="text-3xl font-bold text-white centrar-contenido pb-3">
                                        {title}
                                    </h3>
                                    <hr />
                                    <div className="justify-between flex-row">
                                        {props.data.payment_method !== null &&
                                            <div className='flex flex-row items-center py-3 justify-between' >
                                                <div className='flex flex-row'>
                                                    <img style={{ width: 50, height: 50, marginRight: 20 }} src={imgBank(props.data.payment_method, props.data)} />
                                                    <div>
                                                        <p className='colursPlasholder text-xs text-left capitalize'>Pago por</p>
                                                        {props.data.payment_method !== "credit_card" && <p className="text-white ">{props.data.payment_method === "bancolombia_qr" || props.data.payment_method === "bancolombiaqr" ? "Bancolombia QR" : props.data.payment_method === "bancolombiapay" ? "Bancolombia Pay" : props.data.payment_method === "corresponsal_bancolombia" ? "Corresponsal Bancolombia" : props.data.payment_method === "bancolombia_transfer" ? "Botón Bancolombia" : props.data.payment_method === "btc" ? "BTC" : props.data.payment_method === "pse" ? "PSE" : props.data.payment_method === "credit_card_international" ? "Tarjeta de crédito" : props.data.payment_method === "efecty" ? "Efecty" : props.data.payment_method}</p>}
                                                        {props.data.payment_method === "credit_card" && <p className="text-white ">{"Tarjeta de crédito"}</p>}
                                                    </div>
                                                </div>
                                                <div className={` ${color}`} style={{ borderWidth: 1.5 }}>
                                                    <p className={` ${colorText} px-1 py-1 text-sm`}>{props.data.state === 0 || props.data.state === 1
                                                        ? "CREADO"
                                                        : props.data.state === 2
                                                            ? "EN PROCESO"
                                                            : props.data.state === 3
                                                                ? "PAGADO"
                                                                : props.data.state === 5 || props.data.state === 6
                                                                    ? "EXPIRADO"
                                                                    : props.data.state === 4
                                                                        ? "REVERSADO"
                                                                        : ""}</p>
                                                </div>
                                            </div>}

                                    </div>
                                    <hr />
                                    <div className="flex items-center mb-4 pt-2  leading-7 margin-10px justify-center">

                                        <button onClick={() => copy(props.data.description)} className=" text-white flex rounded-full text-sm   button-outline-off copy-cursor italic text-center">
                                            {props.data.description}
                                        </button>
                                    </div>
                                    <hr />

                                    <div className="my-4 mb-1  text-lg leading-relaxed   ">
                                        {
                                            props.data.username !== null ?
                                                <div className="flex items-center px-1 leading-7 modal-nombre margin-10px">
                                                    <p className="rounded-full mr-4 text-sm  text-left colursPlasholder" >
                                                        Usuario
                                                    </p>
                                                    <button style={{ width: "70%" }} onClick={() => copy(props.data.username)} className="rounded-full text-sm w-6/12 text-right button-outline-off copy-cursor lower text-white">
                                                        {props.data.username}
                                                    </button>
                                                </div>
                                                :
                                                null
                                        }
                                        {
                                            props.data?.alias !== null && props.data?.alias ?
                                                <div className="flex items-center px-1 leading-7 modal-nombre margin-10px">
                                                    <p className="rounded-full mr-4 text-sm  text-left colursPlasholder" >
                                                        Alias
                                                    </p>
                                                    <button style={{ width: "70%" }} onClick={() => copy(props.data.username)} className="text-white rounded-full text-sm w-6/12 text-right button-outline-off copy-cursor lower">
                                                        {props.data?.alias}
                                                    </button>
                                                </div>
                                                :
                                                null
                                        }
                                        <hr />

                                        {
                                            props.data.payer_name !== null ?
                                                <div className="flex items-center px-1 leading-7 modal-nombre margin-10px margin-top-10px">
                                                    <p className="rounded-full mr-4 text-sm  text-left colursPlasholder" >
                                                        Nombre
                                                    </p>
                                                    <button style={{ width: "70%" }} onClick={() => copy(props.data.payer_name)} className="text-white rounded-full text-sm w-6/12 text-right button-outline-off copy-cursor lower">
                                                        {props.data.payer_name}
                                                    </button>
                                                </div>
                                                :
                                                null
                                        }

                                        {
                                            props.data.payer_id !== null
                                                ?
                                                <div className="flex items-center px-1 leading-7 margin-10px">
                                                    <p className="rounded-full mr-4 text-sm w-6/12 text-left colursPlasholder">
                                                        Documento
                                                    </p>
                                                    <button onClick={() => copy(props.data.payer_id)} className="text-white rounded-full text-sm w-6/12 text-right button-outline-off copy-cursor">

                                                        {props.data.payer_id_type === "Cédula de Ciudadanía" ? 'CC ' : props.data.payer_id_type} {FormatDocument(props.data.payer_id)}
                                                    </button>
                                                </div>
                                                :
                                                null
                                        }


                                        <div className="flex items-center px-1 leading-7 margin-10px">
                                            <p className="rounded-full mr-4 text-sm w-6/12 text-left colursPlasholder">
                                                Monto
                                            </p>
                                            <button onClick={() => copy(props.data.amount)} className="text-white rounded-full text-sm w-6/12 text-right button-outline-off copy-cursor">
                                                <span>
                                                    {` ${FormatAmount(props.data.amount, 1)}`}<span style={{ fontSize: 9 }}>{`${FormatAmount(props.data.amount, 2)}`}</span>
                                                </span>

                                            </button>
                                        </div>
                                        {
                                            props.data.payer_email !== null ?


                                                <div className="flex items-center px-1 leading-7 margin-10px justify-between">
                                                    <p className="rounded-full mr-4 text-sm  text-left colursPlasholder">
                                                        Correo
                                                    </p>
                                                    <button onClick={() => copy(props.data.payer_email)} className="text-white flex rounded-full text-sm  text-right button-outline-off copy-cursor">

                                                        {props.data.payer_email}
                                                    </button>
                                                </div>
                                                :
                                                null
                                        }
                                        {
                                            props.data.payer_phone !== null
                                                ?
                                                <div className="flex items-center px-1 leading-7 margin-10px">
                                                    <p className="rounded-full mr-4 text-sm w-6/12 text-left colursPlasholder">
                                                        Teléfono
                                                    </p>
                                                    <button onClick={() => copy(props.data.payer_phone)} className="text-white rounded-full text-sm w-6/12 text-right button-outline-off copy-cursor">
                                                        {FormatPhoneNumber(props.data.payer_phone)}
                                                    </button>
                                                </div>
                                                :
                                                null
                                        }


                                        <div className="flex items-center px-1 leading-7 margin-10px">
                                            <p className="rounded-full mr-4 text-sm w-6/12 text-left colursPlasholder">
                                                URL
                                            </p>
                                            <a target="_blank" href={/* process.env.REACT_APP_API_URL */COBRU_ME + props.data.url} className="text-white rounded-full text-sm w-6/12 text-right button-outline-off cursor-pointer links">
                                                {props.data.url}
                                            </a>
                                        </div>
                                        <hr />
                                        {(props.data.payment_method === "credit_card" || props.data.payment_method === "credit_card_international") && isSuperUser == true ? <div className="flex items-center px-1 leading-7 margin-10px margin-top-10px">
                                            <p className="rounded-full mr-4 text-sm w-6/12 text-left colursPlasholder">
                                                Tarjeta
                                            </p>

                                            <p className="rounded-full text-sm w-6/12 text-right text-white">
                                                {maskCardNumber(props.data.card_info?.card_number)}
                                            </p>
                                        </div>
                                            :
                                            null
                                        }
                                        {(props.data.payment_method === "credit_card" || props.data.payment_method === "credit_card_international") && <div className="flex items-center px-1 leading-7 margin-10px margin-top-10px">
                                            <p className="rounded-full mr-4 text-sm w-6/12 text-left colursPlasholder">
                                                Franquicia
                                            </p>

                                            <p className="rounded-full text-sm w-6/12 text-right text-white">
                                                {props.data.payment_method === "credit_card_international" ? `${props.data.card_info?.franchise} Internacional` : props.data.card_info?.franchise}
                                            </p>
                                        </div>}
                                        <hr />
                                        {!!props.data.date_created && <div className="flex items-center px-1 leading-7 margin-10px margin-top-10px">
                                            <p className="rounded-full mr-4 text-sm text-left colursPlasholder">
                                                Creado
                                            </p>
                                            <p className="rounded-full text-sm w-full text-right text-white">
                                                {moment(props.data.date_created).format('DD/MM/YYYY HH:mm A')}
                                            </p>
                                        </div>}
                                        {(props.data.expiration_date && props.data.state === 5) && <div className="flex items-center px-1 leading-7 margin-10px margin-top-10px">
                                            <p className="rounded-full mr-4 text-sm text-left colursPlasholder">
                                                Expirado
                                            </p>
                                            <p className="rounded-full text-sm w-full text-right text-white">
                                                {moment(props.data.expiration_date).format('DD/MM/YYYY HH:mm A')}

                                            </p>
                                        </div>}
                                        {!!props.data.date_payed && <div className="flex items-center px-1 leading-7 margin-10px margin-top-10px">
                                            <p className="rounded-full mr-4 text-sm text-left colursPlasholder">
                                                {"Pagado"}
                                            </p>
                                            <p className="rounded-full text-sm w-full text-right text-white">
                                                {moment(props.data.date_payed).format('DD/MM/YYYY HH:mm A')}
                                            </p>
                                        </div>}
                                        {(props.data.expiration_date && props.data.state === 4) && <div className="flex items-center px-1 leading-7 margin-10px margin-top-10px">
                                            <p className="rounded-full mr-4 text-sm text-left">
                                                Reversado
                                            </p>
                                            <p className="rounded-full text-sm w-full text-right">
                                                {moment(props.data.expiration_date).format('DD/MM/YYYY HH:mm A')}

                                            </p>
                                        </div>}




                                    </div>



                                </div>

                                <div className="text-2xl centrar-contenido">

                                </div>
                                <div className="items-center justify-end p-2 border-gray-300 rounded-b">
                                    <p className="text-gray-700 text-center text-xs font-hairline">

                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className=" fixed inset-0 z-40 " style={{
                        background: 'rgba(47, 46, 44, 0.5)',
                        backdropFilter: 'blur(10px)',
                    }}></div>
                </>
            ) : null}
        </>
    );
}
