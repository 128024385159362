import React, { useEffect, useState } from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { localeDataPicker } from "../containers/common/functions";
import { BsFillCalendarFill } from "react-icons/bs";
import variablesDomain from "../helpers/variablesDomain";
import moment from "moment";
import Numeral from "numeral";

const ReportWithDate = ({
  title = "Titulo reporte",
  labelOptionReport = "Tipo de reporte",
  optionsReport = [{ label: "Label", value: "value",isDisabled:false }],
  handleClickButton = () => {},
  showInput=false,
  showCheckInput=true,
  valueCheckInput=true,
  showOptions=true,
  placeholderInput='',
  isAmountInput=false
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [valueOptions, setValueOptions] = useState(optionsReport[0]?.value);
  const [start_date, setStart_date] = useState(moment().startOf("month"));
  const [end_date, setEnd_date] = useState(moment().endOf("month"));
  const [label, setLabel] = useState("");
  const [sendEmail, setSendEmail] = useState(valueCheckInput);
  const ranges = {
    Ayer: [moment().subtract(1, "days"), moment().subtract(1, "days")],
    "Últimos 7 Dias": [moment().subtract(6, "days"), moment()],
    "Últimos 30 Días": [moment().subtract(29, "days"), moment()],
    "Último Mes": [moment().startOf("month"), moment().endOf("month")],
    "Mes Anterior": [
      moment().subtract(1, "month").startOf("month"),
      moment().subtract(1, "month").endOf("month"),
    ],
  };
  const [valor, setValor] = useState(0)
  const locale = localeDataPicker;
  const maxDate = moment();
  const [inputValue, setinputValue] = useState('')
  const handleEvent = (event, picker) => {
    if (event.type === "apply") {
      setStart_date(picker.startDate);
      setEnd_date(picker.endDate);
    }
  };


  const handleChangeWithDrawAmount = (amountValue) => {
    let amount = parseInt(
        amountValue.target.value.toString().replace(/\D/g, "")
    );
    setValor(amount)
    let withdrawAmount = Numeral(amount).format("0,0[.]00").replace(/,/g, ".");
    setinputValue(withdrawAmount);

};

  useEffect(() => {
    let start = start_date.format("MMM D YYYY");
    let end = end_date.format("MMM D YYYY");
    let label = start + " - " + end;
    if (start === end) {
      label = start;
    }
    setLabel(label);
  }, [start_date, end_date]);
  
  useEffect(() => {
    console.log('valueOptions',valueOptions)
  }, [valueOptions])
  
  return (
    <div className="flex md:flex-row flex-wrap p-4 bg-white">
      <div className="w-full h-12 p-4">
        <p className="font-body text-lg text-gray-800 py-2">{title}</p>
      </div>

      {
        showCheckInput
        ?
      <div className='w-full p-4 pb-0'>
        <label
          className="block text-gray-500 font-bold"
          style={{marginLeft:'10px'}}
          htmlFor="checkbox-sendemail"
        >
          Enviar al correo
          <input
          id="checkbox-sendemail"
            type="checkbox"
            className="ml-2"
            checked={sendEmail}
            onChange={(event) => {
              setSendEmail(event.target.checked);
            }}
          />
        </label>
      </div>
      :
      null

      }
      <div className=" md:flex w-full md:flex-row">
        <div className="w-full bg-white p-4 text-center">
          <div className="md:flex md:items-center mb-6">
            <div className="md:w-1/3 px-2 py-4">
              <label
                className="block text-gray-500 font-bold md:text-left mb-1 md:mb-0 pr-4"
                htmlFor="inline-full-name"
              >
                {labelOptionReport}
              </label>
            </div>
            <div className="md:w-2/3 px-2 py-4">
              {
                showOptions
                ?

              <div className="relative">
                <select
                  className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="grid-state"
                  onChange={(event) => {
                    setValueOptions(event.target.value);
                  }}
                  value={valueOptions}
                >
                  {optionsReport.map((item) => (
                    <option disabled={item?.isDisabled} value={item.value}>{item.label}</option>
                  ))}

                  {/* <option value={typesReportes.cobrus} selected={true}>Cobrus</option> */}
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg
                    className="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </div>
              </div>
              :
              null
              }

              {
          showInput?
        
            
        <input 
        
             className="block appearance-none w-full mt-3 bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
             placeholder={placeholderInput}
             value={isAmountInput?'$'+inputValue:inputValue}
             onChange={(e)=>{
              if (isAmountInput) {
                handleChangeWithDrawAmount(e)
              }else{
                setinputValue(e.target.value)
              }
            }}
             pattern={isAmountInput?"[0-9]*":''} 
             ></input>
        :
        null
        } 
            </div>
          </div>
        </div>
        {/* {
          showInput?
        <div className="md:flex w-full h-full md:flex-row justify-center">
          <div className="md:w-2/3 px-2 py-4 flex justify-center items-center">
            
        <input 
        style={{'marginBottom':'27px'}}
             className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
             placeholder="Usuario"
             value={inputValue}
             onChange={(e)=>setinputValue(e.target.value)}
             ></input>
             </div>
        </div>
        :
        null
        } */}
        <div className="w-full bg-white p-4 text-center ">
          <div className="max-w-sm rounded overflow-hidden">
            <div className="px-2 py-4">
              <div className="font-bold text-xl mb-2 mt-3 text-black">
                <DateRangePicker
                  initialSettings={{
                    startDate: start_date,
                    endDate: end_date,
                    ranges: ranges,
                    locale: locale,
                    maxDate: maxDate,
                  }}
                  onEvent={handleEvent}
                >
                  <button>
                    <div className="px-1 text-gray-700 float-left">
                      <BsFillCalendarFill />
                    </div>
                    <span className="font-bold text-xs float-left">
                      {label}
                    </span>
                    <div className="float-left text-gray-700">
                      <svg
                        className="fill-current h-4 w-4"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                      >
                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                      </svg>
                    </div>
                  </button>
                </DateRangePicker>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full  bg-white p-4 text-center">
          <div className="max-w-sm rounded overflow-hidden">
            <div className="px-2 py-4">
              <div className="font-bold text-xl mb-2 text-black flex flex-row">
                <button
                  className={`bg-${variablesDomain.classNameColor} hover:bg-green-700 text-white font-bold py-2 px-4 rounded text-sm`}
                  onClick={() =>{

                    console.log("🚀 ~ start_date:", start_date)
                    
                    handleClickButton(
                      valueOptions,
                      start_date,
                      end_date,
                      sendEmail,
                      setIsLoading,
                      isAmountInput?valor:inputValue
                      )
                    }
                  }
                  disabled={isLoading}
                >
                  {isLoading
                    ? sendEmail
                      ? "Enviando..."
                      : "Generando..."
                    : sendEmail
                    ? "Enviar Reporte"
                    : "Generar Reporte"}
                </button>
                {/* <CSVLink
                        data={data}
                        filename={'cobru-' + label + '.csv'}
                        className="hidden"
                        ref={csvLink}
                        target="_blank"
                    /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportWithDate;
